import axios from "axios";
import { DataRunsTransformer } from "../transformers/DataRunsTransformer.ts";
import { AuthService } from "../../common/services/AuthService.ts";

const dataRunsTransformer = DataRunsTransformer
const authservice = AuthService

async function getDataRunsList() {
    var token = authservice.getToken()
    let data

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results',
        headers: {
            'Authorization': token
        }
    };

    await axios.request(config)
        .then((response) => {
            data = response.data
            // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });

    return data

}
async function getDataRunsSpreadsheet() {
    let data = await getDataRunsList()
    let transformedData = dataRunsTransformer.transformRuns(data)
    return transformedData
}

async function getRunsDropdown() {
    let data = await getDataRunsList()
    let transformedData
    if(data && data.length>0){
        transformedData = dataRunsTransformer.transformRunsDropdown(data)
    }
    return transformedData
}
async function getCompareRuns(runAId, runBId) {
    var token = authservice.getToken()

    let AData
    let BData

    let configA = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + runAId,
        headers: {
            'Authorization': token
        }
    };

    await axios.request(configA)
        .then((response) => {
            AData = response.data
        })
        .catch((error) => {
            console.log(error);
        });


    let configB = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + runBId,
        headers: {
            'Authorization': token
        }
    };

    await axios.request(configB)
        .then((response) => {
            BData = response.data
        })
        .catch((error) => {
            console.log(error);
        });

        return dataRunsTransformer.transformCompareData(AData, BData)


}
async function getRun(uuid){
    var token = authservice.getToken()
    let data

    let configB = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + uuid,
        headers: {
            'Authorization': token
        }
    };

    await axios.request(configB)
        .then((response) => {
            data = response.data
        })
        .catch((error) => {
            console.log(error);
        });

        return data

}


export const DataRunsService = {
    getDataRunsList,
    getRunsDropdown,
    getDataRunsSpreadsheet,
    getCompareRuns,
    getRun
}