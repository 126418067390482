import React, { useState, useEffect } from "react";
import Spreadsheet from "../../../common/components/Spreadsheet";
import './components.css'
import { Trash, FilterSquare, Pencil } from "react-bootstrap-icons";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import EditPromptModal from "./EditPromptModal";
import { Navigate } from "react-router-dom";


export default function PromptResults(props) {

    const [showEdit, setShowEdit] = useState(false)
    const [selectCount, setSelectCount] = useState(0)
    const [selected, setSelected] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [data, setData] = useState([])
    const [filterType, setFilterType] = useState("All")

    const [redirectHome, setRedirectHome] = useState(false)

    const fields = [{ name: "Prompt ID", hidden: false }, { name: "Name", hidden: false },
    { name: "Prompt", hidden: false }, { name: "Type", hidden: false },
    { name: "Created", hidden: false }, { name: "Used on Runs", hidden: false }, { name: "data", hidden: true }]

    const typeList = ["All", "Keyword", "Topic", "Sentiment", "Emotion"]

    const [message, setMessage] = useState("")
    const [clear, setClear] = useState()

    function getSelected(selected, count) {
        setSelected(selected)
        setSelectCount(count)
    }

    function handleSetFilter(key, { target: { text } }) {
        let type
        switch (text) {
            case "Keyword":
                type = "keywords"
                break;
            case "Topic":
                type = "topics"
                break;
            case "Sentiment":
                type = "sentiment"
                break;
            case "Emotion":
                type = "emotions"
                break;
            default:
                type = ""
                break;
        }

        setFilterType(type)
    }

    useEffect(() => {
        if (props.data && props.data.tablePrompts && props.data.tablePrompts.length > 0) {
            setData(props.data.tablePrompts)
        }
    }, [props])

    useEffect(() => {
        var newFilteredData = []
        if (filterType === "All") {
            newFilteredData = data
        }
        else {
            data.forEach(element => {
                if (element[3] === filterType) {
                    newFilteredData.push(element)
                }
            });
        }
        setFilteredData(newFilteredData)
    }, [data, filterType])

    useEffect(() => {
        if (props.deleteResponse && props.deleteResponse.message) {
            setMessage("something went wrong: " + props.deleteResponse.message)
        }
        else if(props.deleteResponse !== undefined) {
            setMessage("deleted")

            setTimeout(() => {
                setMessage("")
            }, 1000)
        }
    }, [props.deleteResponse])

    function deletePrompt() {
        setShowEdit(false)
        setMessage("deleting...")
        selected.forEach(element => {
            try {
                props.deletePrompt(element[0])
            } catch (error) {
                console.log(error)
            }
        })

        setClear(Date.now)
        setSelected([])
        setSelectCount(0)
    }
    function addToRun() {
        const promptData = selected[0][selected[0].length - 1]
        var promptType
         switch (promptData.function) {
                case "keywords":
                    promptType = "Keyword"
                    break;
                case "topic":
                    promptType = "Topic"
                    break;
                case "sentiment":
                    promptType = "Sentiment"
                    break;
                case "emotions":
                    promptType = "Emotion"
                    break;
                default:
                    promptType = ""
                    break;
            }
        var testFormData = {
            type: '',
            aiVendor: { dropdown: '', value: '' },
            aiModel: { dropdown: '', value: '' },
            note: { dropdown: '', value: '' },
            group: { dropdown: '', value: '' },
            instruction: { dropdown: '', value: '' },
            temp: '',
            tokens: '',
            topP: ''
        }
        var savedTestFormData = sessionStorage.getItem("newTestForm")

        if (savedTestFormData && savedTestFormData !== undefined && savedTestFormData !== "undefined") {
            testFormData = JSON.parse(savedTestFormData)
        }
        testFormData.instruction = { dropdown: promptData.prompt_text, value: JSON.stringify(promptData)}
        testFormData.type = promptType

        console.log(testFormData)
    
        sessionStorage.setItem("newTestForm", JSON.stringify(testFormData))

        setRedirectHome(true)
    }

    return (
        <div className="">
            {redirectHome ? <Navigate to="/"></Navigate> : null}
            <div>
                <div className="filters-section inline">
                    {selectCount === 0 ?
                        <div className="inline">
                            <Button className="compare-button" disabled>Run Test</Button>
                            <div className="button-link deactivated"><Pencil /> Create From</div>
                            <div className="button-link deactivated"><Trash /> Delete</div>
                        </div>
                        : selectCount === 1 ?
                            <div className="inline">
                                <Button className="compare-button" onClick={() => addToRun()}>Run Test</Button>
                                <div className="button-link" onClick={() => setShowEdit(true)}><Pencil /> Create From</div>
                                <div className="button-link danger" onClick={() => deletePrompt()}><Trash /> Delete</div>
                            </div>
                            : <div className="inline">
                                <Button className="compare-button" disabled>Run Test</Button>
                                <div className="button-link deactivated"><Pencil /> Create From</div>
                                <div className="button-link danger" onClick={() => deletePrompt()}><Trash /> Delete</div>
                            </div>
                    }
                    <div className="save-notif">{message}</div>
                    <div className=" inline ms-auto">
                        <Button variant="btn btn-link-secondary"><FilterSquare></FilterSquare></Button>
                        <DropdownButton className={filterType ? "medium selected" : "medium"} title={<div className="dropdown-title">{filterType ? filterType : "Filter by Type"}</div>} id="type" onSelect={handleSetFilter}>
                            {typeList.map((type, i) => <Dropdown.Item key={i} value={type} id="type">{type}</Dropdown.Item>)}
                        </DropdownButton>
                    </div>
                </div>
                <Spreadsheet
                    fields={fields}
                    data={filteredData}
                    isSelectable={true}
                    type="prompt"
                    getSelected={getSelected}
                    defaultSort={4}
                    clear={clear}
                ></Spreadsheet>
            </div>
            {showEdit ? <EditPromptModal
                handleEditClose={() => setShowEdit(false)}
                selected={selected[0]}
                deletePrompt={deletePrompt}
                createPrompt={props.createPrompt}
            ></EditPromptModal> : null}
        </div>
    )
}