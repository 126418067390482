import axios from "axios";
import { DataRunsTransformer } from "../transformer/DataRunsTransformer.ts";
import { AuthService } from "../../common/services/AuthService.ts";

const dataRunsTransformer = DataRunsTransformer
const authservice = AuthService

async function getDataRunsList() {
    var token = authservice.getToken()
    let data

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results',
        headers: {
            'Authorization': token
        }
    };

    await axios.request(config)
        .then((response) => {
            data = response.data
            // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });

    return data

}
async function getDataRunsSpreadsheet() {
    let data = await getDataRunsList()
    let transformedData = dataRunsTransformer.transformRuns(data)
    return transformedData
}

async function getRunsDropdown() {
    let data = await getDataRunsList()

    let transformedData = dataRunsTransformer.transformRunsDropdown(data)
    return transformedData
}
async function getRun(uuid){
    var token = authservice.getToken()
    let data

    let configB = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + uuid,
        headers: {
            'Authorization': token
        }
    };

    await axios.request(configB)
        .then((response) => {
            data = response.data
        })
        .catch((error) => {
            console.log(error);
        });

        return data

}
async function deleteRun(uuid){
    var token = authservice.getToken()

    let configB = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + uuid,
        headers: {
            'Authorization': token
        }
    };

    const results = await axios.request(configB)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error);
            return error
        });

    return results
}


export const DataRunsService = {
    getDataRunsList,
    getRunsDropdown,
    getDataRunsSpreadsheet,
    getRun,
    deleteRun
}