import React, { useEffect, useState } from "react";
import './components.css'
import { Modal, Button } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import  { domToReact } from 'html-react-parser';

export default function ViewRunModal(props) {

    const [contrastColors, setContrastColors] = useState(false)
    const [parsable, setParsable] = useState(true)

    const [markupText,setMarkupText] = useState()

    useEffect(()=>{
        if(!markupText){
            setMarkupText(getMarkupText(props.selected))
        }
    },[markupText, props.selected])
    

    function getMarkupText(data){
        let keywords = []
    
        let keywordsRaw = data.keywords.results
    
        let parse = true
    
        try {
            keywordsRaw.replace(/&quot;/ig,'"');
            keywords = JSON.parse(keywordsRaw).keywords
        } catch (error) {
            console.log(error)
            keywords = data.keywords.results
            parse = false  
            setParsable(false)
        }
    
        let markup = data.note.note_text

        if(parse){
            keywords.forEach(element => {
                markup = markup.replace(element, `<span class="markup-text">` + element + `</span>`)
            });
        }
        
        return markup
    }


    const options = {
        replace: ({ attribs, children }) => {
          if (!attribs) return;
          if (attribs.id === 'main') {
            return (
              <h1 style={{ fontSize: 42 }}>{domToReact(children, options)}</h1>
            );
          } else if (attribs.class === 'markup-text') {
            return (
              <span style={{ color: 'goldenrod' }}>
                {domToReact(children, options)}
              </span>
            );
          }
        }
      };

    return (
        <Modal dialogClassName="edit-modal" show={true} onHide={props.handleHideView} >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="inline-modal margin-bottom">
                    <div className="modal-title-text">
                        View Run
                    </div>
                    <div className="inline-modal flex-right-modal">

                    </div>
                </div>
                <div>
                    <b>Result ID: </b>{props.selected.uuid}<br/>
                    <b>Run ID: </b>{props.selected.run_id}
                </div>
                <div>
                    <b>LLM: </b> {props.selected.llm.vendor_name} {props.selected.llm.engine_name}
                </div>
                <div className="margin">
                    <b>Prompt ID:</b>  {props.selected.note.uuid}  <br />
                    <b>Prompt Title:</b>  {props.selected.prompt.name} <br />
                    <b>Prompt Text:</b>  {props.selected.prompt.prompt_text} <br />
                </div>
                <div className="margin">
                    <b>Note ID:</b>{props.selected.note.uuid} <br />
                    <b>Note Title:</b>  {props.selected.note.name} <br />
                    <b>Note Text:</b>{contrastColors? HTMLReactParser(markupText, options): props.selected.note.note_text}<br />

                </div>

                <div className="margin">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => setContrastColors(!contrastColors)} /> 
                        Contrast Colors
                        {!parsable && contrastColors? <span style={{"color":"goldenrod"}}> Unable to parse JSON </span>:null}
                    </div>
                    <br></br>
                    <b>Result:</b>  {props.selected.keywords.results}  <br />
                </div>
                <div className="margin">
                    <b>Temperature:</b>  {props.selected.parameters.temperature}  <br />
                    <b>Top P:</b>  {props.selected.parameters.top_p} <br />
                    <b>Max Tokens:</b>  {props.selected.parameters.max_tokens} <br />
                </div>
                <div>
                    <b>Date Created: </b>{props.selected.created_at}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-outline-primary" onClick={props.handleHideView}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}