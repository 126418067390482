import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown, DropdownButton, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import './components.css'
import { XCircleFill, FiletypeCsv } from "react-bootstrap-icons";

export default function NewTest(props) {

    const typeList = ["Keyword", "Topic", "Sentiment", "Emotion"]

    const blankAttribute = { dropdown: '', value: '' }
    const [groupName, setGroupName] = useState()
    const [changeGroup, setChangeGroup] = useState(false)

    const [vendorList, setVendorList] = useState([])
    const [modelList, setModelList] = useState([])
    const [fullModelList, setFullModelList] = useState([])

    const [instructionsList, setInstructionsList] = useState([])
    const [notesList, setNotesList] = useState([])
    const [groupsList, setGroupsList] = useState([])

    const [noteKey, setnoteKey] = useState([Date.now()])

    const [formData, setFormData] = useState(props.currentFormData)
    const [isComplete, setIsComplete] = useState(false)

    const [outOfRange, setOutOfRange] = useState({ temp: false, tokens: false, topP: false })

    const [testHasRun, setTestHasRun] = useState(false)

    const [promptList, setPromptList] = useState([])

    const [isCsv, setIsCsv] = useState(false) //true if a csv has been uploaded
    const [csvError, setCsvError] = useState(false); //is true if file is not a csv
    const [file, setFile] = useState() //uploaded csv file
    const [refKey, setRefKey] = useState(Date.now) //key to reset ref when csv is removed
    const hiddenFileInput = useRef(null); //ref for uploading file

    const [uploadError, setUploadError] = useState(false)
    const [uploadComplete, setUploadComplete] = useState(false)

    const [useGroup, setUseGroup] = useState(false)

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.notesList && props.dropdownData.notesList.length !== 0) {
            setNotesList(props.dropdownData.notesList)
            setGroupsList(props.dropdownData.groupsList)
            setVendorList(props.dropdownData.llmsList.vendorList)
            setInstructionsList(props.dropdownData.promptsList)
            setFullModelList(props.dropdownData.llmsList.engineList)
        }
    }, [props.dropdownData])


    useEffect(() => {
        let newModelList = []
        if (formData.aiVendor && fullModelList && fullModelList.length > 0 && fullModelList[0].dropdown) {
            fullModelList.forEach(model => {
                if (formData.aiVendor.dropdown === model.dropdown.vendor) {
                    newModelList.push(model)
                }
            });
        }
        setModelList(newModelList)
    }, [formData, fullModelList])

    useEffect(() => {
        let newType
        switch (formData.type) {
            case "Keyword":
                newType = "keywords"
                break;
            case "Topic":
                newType = "topics"
                break;
            case "Sentiment":
                newType = "sentiment"
                break;
            case "Emotion":
                newType = "emotions"
                break;
            default:
                newType = ""
                break;
        }

        let newPromptList = []
        instructionsList.forEach(element => {
            if (JSON.parse(element.value).function === newType) {
                newPromptList.push(element)
            }
        })
        setPromptList(newPromptList)
    }, [formData, formData.type, instructionsList])

    function handleSetVendor(key, { target }) {
        let newObject = { dropdown: target.text, value: target.getAttribute("value") }
        setFormData({ ...formData, [target.id]: newObject, aiModel: blankAttribute })
    }

    function handleSetType(key, { target: { text } }) {

        if (text !== formData.type) {
            setFormData({ ...formData, type: text, aiVendor: blankAttribute, aiModel: blankAttribute, instruction: blankAttribute, temp: "", tokens: "", topP: "" })
        }
    }

    function handleGroupSelect(key, { target: { text } }) {
        props.getNotesByGroup(text)
        setGroupName(text)
        setChangeGroup(true)
    }

    useEffect(()=>{
        if(changeGroup && props.groupNotesList.length>0){
            setFormData({ ...formData, note: { dropdown: groupName, value: JSON.stringify(props.groupNotesList) }, group:{ dropdown: groupName, value: JSON.stringify(props.groupNotesList) }  })
            setUseGroup(true)
            setChangeGroup(false)
        }
    },[props.groupNotesList, formData, groupName, changeGroup])

    useEffect(() => {
        function checkComplete() {
            if (testHasRun) {
                return false
            }
            var isTemp = false
            var isNote = false
            var propsInRange = false
            if (formData.temp && formData.temp !== '' && formData.temp !== "NaN") {
                isTemp = true
            }
            if (!outOfRange.temp && !outOfRange.tokens && !outOfRange.topP) {
                propsInRange = true
            }
            if (formData.note.dropdown || file) {
                isNote = true
            }
            if (isNote && isTemp && propsInRange) {
                return true
            }
            else {
                return false
            }
        }

        setIsComplete(checkComplete())
        if (formData.type || formData.note.dropdown || file) {
            props.setTestForm(formData)
        }
    }, [formData, props, outOfRange, testHasRun, file]);

    useEffect(() => {
        setTestHasRun(false)
    }, [formData])

    function handleFormChange(key, { target }) {
        let newObject = { dropdown: target.text, value: target.getAttribute("value") }
        setFormData({ ...formData, [target.id]: newObject })
    }

    function handleUpload() {
        props.submitNotesCsv(file).then(value => {
            if (value.success === true) {
                setFormData({ ...formData, note: value.data })
                setUploadComplete(true)
            }
            else {
                setUploadError(true)
                return
            }
        })
    }

    useEffect(() => {
        if (uploadComplete) {
            props.onRun(formData, isCsv)
        }
        setUploadComplete(false)
    }, [uploadComplete, formData, isCsv, props])

    function handleRun() {
        setTestHasRun(true)
        console.log(formData)
        props.onRun(formData, isCsv)
    }
    function handleClear() {
        props.handleClear()
    }
    function handleNoteClear() {
        setFormData({ ...formData, note: blankAttribute, group: blankAttribute })
        setnoteKey(Date.now())
        setUseGroup(false)
    }
    function decimalBlur(e) {
        let id = e.target.id
        let value = parseFloat(e.target.value)
        if (id === "tokens") {
            value = value.toFixed(0)
        }
        else {
            value = value.toFixed(2)
        }
        setFormData({ ...formData, [id]: value })
    }

    function checkValue(e) {
        let min = parseFloat(e.target.min)
        let max = parseFloat(e.target.max)
        let value = parseFloat(e.target.value)
        let id = e.target.id
        if (value < min || value > max) {
            setOutOfRange({ ...outOfRange, [id]: true })
        }
        else {
            setOutOfRange({ ...outOfRange, [id]: false })
        }
        setFormData({ ...formData, [id]: value })
    }

    function handleClick(e) { //ties upload button to ref that uploads the file
        hiddenFileInput.current.click();
    }

    function handleChange(e) { //uploads csv file
        if (e.target.files[0].name.slice(-3) === "csv") {
            setFile(e.target.files[0])
            setCsvError('')
            setIsCsv(true)
        }
        else {
            setCsvError(true)
        }
    }

    function handleIconClick(e) { //removes csv file 
        setFile()
        setIsCsv(false)
        setRefKey(Date.now)
    }

    return (
        <div className=" ">
            <span className="form-box-left">
                <span className="form-section">
                    <h4>1. Note Entry</h4>
                    <div className="inline">
                        {!formData.note.dropdown && !isCsv ?
                            <div className="inline margin" key={noteKey}>
                                <DropdownButton className={formData.note ? "large selected" : "large"} title={formData.note.dropdown ? formData.note.dropdown : "Select Note"} id="note" onSelect={handleFormChange}>
                                    {notesList.map((note, i) => <Dropdown.Item key={i} value={note.value} id="note">{note.dropdown}</Dropdown.Item>)}
                                </DropdownButton>
                                <DropdownButton className={formData.group ? "large selected" : "large"} title={formData.group.dropdown ? formData.group.dropdown : "Select Group"} id="group" onSelect={handleGroupSelect}>
                                    {groupsList.map((group, i) => <Dropdown.Item key={i} value={group.value} id="group">{group.dropdown}</Dropdown.Item>)}
                                </DropdownButton>
                                <input type="file" key={refKey} ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChange} />
                                <Button type="file" variant="outline-primary" onClick={handleClick}>Upload CSV</Button>
                            </div>

                            : formData.note.dropdown && !useGroup ?
                                <div className="inline margin" key={noteKey}>
                                    <DropdownButton className={formData.note ? "large selected" : "large"} value={formData.note.dropdown} title={<div className="dropdown-title">{formData.note.dropdown}</div>} id="note" onSelect={handleFormChange}>
                                        {notesList.map((note, i) => <Dropdown.Item key={i} value={note.value} id="note">{note.dropdown}</Dropdown.Item>)}
                                    </DropdownButton>
                                    <div className="blue icon" onClick={handleNoteClear}> Reset <XCircleFill /></div>
                                </div>

                                :
                                <div className="inline margin" key={noteKey}>
                                <DropdownButton className={formData.group ? "large selected" : "large"} title={formData.group.dropdown ? formData.group.dropdown : "Select Group"} id="group" onSelect={handleGroupSelect}>
                                    {groupsList.map((group, i) => <Dropdown.Item key={i} value={group.value} id="group">{group.dropdown}</Dropdown.Item>)}
                                </DropdownButton>
                                <div className="blue icon" onClick={handleNoteClear}> Reset <XCircleFill /></div>
                                </div>
                        }

                        {isCsv ? <div className="margin"><FiletypeCsv /> {file.name} <XCircleFill color="#0D6EFD" onClick={handleIconClick} cursor={"pointer"} /></div>
                            : null}

                        {csvError ? <div className="alert csv-alert alert-danger" role="alert">That is not a CSV file</div> : null}
                        {uploadError ? <div className="alert csv-alert alert-danger" role="alert">Issue uploading CSV file</div> : null}

                    </div>
                    <h4>2. Type of Run</h4>
                    <div className="inline margin">
                        <DropdownButton className={formData.type ? "medium selected" : "medium"} title={<div className="dropdown-title">{formData.type ? formData.type : "Select Type"}</div>} id="group" onSelect={handleSetType}>
                            {typeList.map((type, i) => <Dropdown.Item key={i} value={type} id="type">{type}</Dropdown.Item>)}
                        </DropdownButton>
                    </div>

                    <h4>3. Natural Language Instructions</h4>
                    <div className="inline margin">
                        <DropdownButton className={formData.instruction.dropdown ? "xlarge selected" : "xlarge"} disabled={(formData.type && (formData.note.dropdown || isCsv)) ? false : true} title={<div className="dropdown-title">{formData.instruction.dropdown ? formData.instruction.dropdown : "Select Instruction"}</div>} id="group" onSelect={handleFormChange}>
                            {promptList.map((instruction, i) => <Dropdown.Item key={i} value={instruction.value} id="instruction">{instruction.dropdown}</Dropdown.Item>)}
                        </DropdownButton>
                    </div>

                    <h4>4. AI Engine</h4>
                    <div className="inline margin">
                        <DropdownButton className={formData.aiVendor.dropdown ? "large selected" : "large"} disabled={(formData.instruction.dropdown && (formData.note.dropdown || isCsv)) ? false : true} title={<div className="dropdown-title">{formData.aiVendor.dropdown ? formData.aiVendor.dropdown : "Select Vendor"}</div>} id="aiVendor" onSelect={handleSetVendor}>
                            {vendorList.map((vendor, i) => <Dropdown.Item key={i} value={vendor.value} id="aiVendor">{vendor.dropdown}</Dropdown.Item>)}
                        </DropdownButton>
                        <DropdownButton className={formData.aiModel.dropdown ? "large selected" : "large"} disabled={(formData.aiVendor.dropdown && (formData.note.dropdown || isCsv)) ? false : true} title={<div className="dropdown-title">{formData.aiModel.dropdown ? formData.aiModel.dropdown : "Select Model"}</div>} id="aiModel" onSelect={handleFormChange}>
                            {modelList.map((model, i) => <Dropdown.Item key={i} value={model.value} id="aiModel">{model.dropdown.engine_name}</Dropdown.Item>)}
                        </DropdownButton>
                    </div>

                    <h4>5. Parameters</h4>
                    <table className={formData.aiModel.dropdown === '' ? "parameter-table disabled" : "parameter-table"}>
                        <tbody>
                            <tr>
                                <td>Temperature:</td>
                                <td>
                                    <input type="number" step="0.01" max="2.00" min="0.00" disabled={(formData.aiModel.dropdown && (formData.note.dropdown || isCsv)) ? false : true} value={formData.temp} className="form-control parameter" placeholder="Enter" id="temp" onBlur={decimalBlur} onChange={checkValue}></input>
                                </td>
                                <td>
                                    *required
                                    <div>Decimals to 10th place: 0.00–2.00</div>
                                </td>
                                <td>
                                    <Alert variant="danger range-alert" show={outOfRange.temp}>Out of range</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td> Max Tokens:</td>
                                <td>
                                    <input type="number" min="1" max="4096" disabled={(formData.aiModel.dropdown && (formData.note.dropdown || isCsv)) ? false : true} className="form-control parameter" value={formData.tokens} placeholder="Enter" id="tokens" onBlur={decimalBlur} onChange={checkValue}></input>
                                </td>
                                <td>
                                    (optional)
                                    <div>Whole numbers: 1–4096</div>
                                </td>
                                <td>
                                    <Alert variant="danger range-alert" show={outOfRange.tokens}>Out of range</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td>Top P:</td>
                                <td>
                                    <input type="number" min="0.00" max="1.00" step="0.01" disabled={(formData.aiModel.dropdown && (formData.note.dropdown || isCsv)) ? false : true} value={formData.topP} className="form-control parameter" placeholder="Enter" id="topP" onBlur={decimalBlur} onChange={checkValue}></input>
                                </td>
                                <td>
                                    (optional)
                                    <div>Decimals to 10th place: 0.00–1.00</div>
                                </td>
                                <td>
                                    <Alert variant="danger range-alert" show={outOfRange.topP}>Out of range</Alert>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>

                <div className="run">
                    <Button className="button-run" disabled={!isComplete} onClick={!isCsv ? handleRun : handleUpload}>Run Test</Button>
                    <Button className="button-run" variant="btn btn-outline-primary" onClick={handleClear}>Clear All</Button>
                </div>
            </span>

            <span className="directions">
                <h1>New Test</h1>
                <h5>How-To:</h5>
                <ol style={{ paddingLeft: "20px", marginTop: "10px" }}>
                    <li><span className="blue">Note: Select </span>either one Note, or one Group, from the database</li>
                    <li><span className="blue">Type: Select </span>the type of test (Keywords, Topics, etc)</li>
                    <li><span className="blue">Instructions: </span>Select from the database</li>
                    <li><span className="blue">AI Engine: </span>Select the Vendor and Model</li>
                    <li><span className="blue">Parameters: </span>Adjust the settings, some fields are optional. Choose from the dropdown list or manually enter text (when applicable).</li>
                </ol>
                When all fields are filled out, press <span className="blue">Run Test</span>.
                <br></br>
                Results will be shown below.
                <br></br>
                <br></br>
                To start over or clear the form, press <span className="blue">Clear All</span>.
            </span>
        </div>
    )
}