function transformRuns(data){
    var runsArray: any[] = []
    data.forEach(element => {
        runsArray.push([element.resultID, element.run_name, element.prompt.function, "group",  element.grade, element.prompt.prompt_text, element.llm.vendor_name + " " + element.llm.engine_name, "1", element.created_at, element])
    });
    return runsArray
}
function transformRunsDropdown(data){
    var runsDropdownList:any[] = []
    data.forEach(element => {
        runsDropdownList.push({dropdown:element.run_name, noteID:element.note.uuid, resultID: element.uuid }) //TODO: here
    })
    return runsDropdownList
}



export const DataRunsTransformer={
    transformRuns,
    transformRunsDropdown
}