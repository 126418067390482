import React ,{useEffect}from "react";
import './components.css'
import { Modal, Button } from "react-bootstrap";
import { Download, Trash } from "react-bootstrap-icons";

export default function ViewNoteModal(props) {
    const currentNote = props.selected[1]
    // const [currentNote, setCurrentNote] = useState(props.selected[2]) 

    useEffect(()=>{
       console.log("selected: ", props.selected)
    })
    
    return (
        <Modal dialogClassName="edit-modal" show={true} onHide={props.handleViewClose} >
            <Modal.Header  closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="inline-modal margin-bottom">
                    <div className="modal-title-text">
                        View Note
                    </div>
                    <div className="inline-modal flex-right-modal">
                        <Button variant="btn btn-outline-primary"><Download /></Button>
                        <Button variant="btn btn-outline-danger"><Trash /> Delete</Button>
                    </div>
                </div>
                <div>
                    Note ID: <b>{props.selected[0]}</b>
                </div>
                <div className="inline-modal margin">
                    Note Name: {props.selected[2]}
                </div>
                <div><b>Note: </b>{currentNote}</div>
                <div className="border-bottom">
                    <b>Group Name: </b> {props.selected[3]}
                </div>
                <div>
                    <b>Date Created: </b> {props.selected[4]}
                </div>
                <div>
                    <b>Date Modified: </b> {props.selected[5]}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-outline-primary" onClick={props.handleViewClose}>
                    Close
                </Button>
                <Button variant="primary" >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}