import React, { useState, useEffect } from "react";
import { Alert, Button, Dropdown, DropdownButton } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import './components.css'

export default function NewPrompt(props) {
    const [formData, setFormData] = useState({
        prompt_text: '',
        type: '',
        name: '',
    })
    const [isComplete, setIsComplete] = useState(false)
    const [results, setResults] = useState()
    const [useCustomName, setUseCustomName] = useState(false)
    const fullDate = new Date()
    const date = fullDate ? fullDate.getFullYear().toString() + (fullDate.getMonth()+1).toString().padStart(2, "0") + fullDate.getDate().toString()
        : null;

    function generateName() {
        
        let name = formData.prompt_text
        let newName = name.split(' ').slice(0, 5).join("-")
        name = newName.replace(/[.,#!$%^&*;:{}=_`~() ]/g, "")

        newName = date + "-" + name
        return newName
    }

    function handleBlur() {
        if (!useCustomName) {
            setFormData({ ...formData, name: generateName() })
        }
    }

    useEffect(() => {
        if (formData.type && formData.name && formData.prompt_text) {
            setIsComplete(true)
        } else setIsComplete(false)
    }, [formData]);

    useEffect(() => {
        if (props.results && props.results.message) {
            setResults(props.results)
        }
    }, [props.results]);

    function handleFormChange({ target: { id, value } }) {
        setFormData({ ...formData, [id]: value })
        if (id === "name") {
            setUseCustomName(true)
        }
    }
    function handleRun(e) {
        const finalPrompt = {
            "name": formData.name,
            "function": formData.type,
            "prompt_text": formData.prompt_text
        }
        props.createPrompt(finalPrompt)
    }

    const typeList = ["Keyword", "Topic", "Sentiment", "Emotion"]
    function handleSetType(key, { target: { text } }) {
        setFormData({ ...formData, type: text })
    }

    return (
        <div>
            <span className="form-section">
                <h4>Create New Prompt</h4>
                <textarea className="form-control text-area margin-bottom" placeholder="Enter Prompt" id="prompt_text" onChange={handleFormChange} onBlur={handleBlur}></textarea>
                <div className="inline">
                    <DropdownButton className={formData.type ? "medium selected" : "medium"} title={<div className="dropdown-title">{formData.type ? formData.type : "Select Type"}</div>} id="type" onSelect={handleSetType}>
                        {typeList.map((type, i) => <Dropdown.Item key={i} value={type} id="type">{type}</Dropdown.Item>)}
                    </DropdownButton>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Name this Prompt here" id="name" onChange={handleFormChange} value={formData.name}></input>
                    </span>
                </div>
                {results && results.message ? (results.success ? <Alert className="alert" variant="success">{results.message}</Alert> : <Alert className="alert" variant="danger">{results.message + ": " + results.data}</Alert>) : null}
            </span>
            <div className="run">
                {isComplete ? <Button className="button-run" onClick={handleRun}>Create</Button> : <Button className="button-run" disabled onClick={handleRun}>Create</Button>}
            </div>
        </div>
    )
}