function transformRuns(data){
    var runsArray: any[] = []
    data.forEach(element => {
        runsArray.push([element.uuid, element.run_name, "type", element.grade, element.prompt.prompt_text, element.llm.vendor_name + " " + element.llm.engine_name, "1", element.created_at, "Manual Entry"])
    });
    return runsArray
}
function transformRunsDropdown(data){
    var runsDropdownList:any[] = []
    data.forEach(element => {
        runsDropdownList.push({dropdown:element.run_name, noteID:element.note.uuid, resultID: element.uuid }) //TODO: here
    })
    return runsDropdownList
}
function transformCompareData(AData, BData){
    let AKeywords:any[] = []
    let BKeywords:any[] = []

    let AKeywordsRaw = AData.keywords.results
    let BKeywordsRaw = BData.keywords.results

    let parseA = true
    let parseB = true

    try {
        AKeywordsRaw.replace(/&quot;/ig,'"');
        AKeywords = JSON.parse(AKeywordsRaw).keywords
    } catch (error) {
        console.log(error)
        AKeywords = AData.keywords.results.split()    
        parseA = false  
    }

    try {
        BKeywordsRaw.replace(/&quot;/ig,'"');
        BKeywords = JSON.parse(BKeywordsRaw).keywords
    } catch (error) {
        console.log(error)
        BKeywords = BData.keywords.results.split()     
        parseB = false 
    }

    let AUnique: any[] = []
    let BUnique: any[] = []
    let same: any[] = []
    let AMarkup =AData.note.note_text
    let BMarkup = BData.note.note_text
    let APrompt =AData.prompt.prompt_text
    let BPrompt = BData.prompt.prompt_text

    AKeywords.forEach(element => {
        AMarkup = AMarkup.replace(element, `<span class="markup-text">` + element + `</span>`)
       if(!BKeywords.includes(element)){
        AUnique.push(element)
       } 
       else{
        same.push(element)
       }
    });

    BKeywords.forEach(element => {
        BMarkup = BMarkup.replace(element, `<span class="markup-text">` + element + `</span>`)
        if(!AKeywords.includes(element)){
         BUnique.push(element)
        } 
     });
    
    var newCompareData:any[] = []
    let noteID = AData.note.uuid
    let runA = {
        aiVendor: AData.llm.vendor_name, 
        aiModel: AData.llm.engine_name, 
        total: AKeywords.length,
        keywords: AKeywords.toString().replaceAll(",", ", "), 
        unique: AUnique.length, 
        uniqueKeywords: AUnique.toString().replaceAll(",", ", "),
        markupText: AMarkup,
        resultID: AData.uuid, //TODO: here
        tokens: AData.parameters.max_tokens,
        topP: AData.parameters.top_p,
        temp: AData.parameters.temperature,
        prompt: APrompt,
        parseable: parseA
        }

    let runB = {
        aiVendor: BData.llm.vendor_name, 
        aiModel: BData.llm.engine_name, 
        total: BKeywords.length, 
        keywords: BKeywords.toString().replaceAll(",", ", "), 
        unique: BUnique.length, 
        uniqueKeywords: BUnique.toString().replaceAll(",", ", "),
        markupText: BMarkup,
        resultID: BData.uuid, //TODO: here
        tokens: BData.parameters.max_tokens,
        topP: BData.parameters.top_p,
        temp: BData.parameters.temperature,
        prompt: BPrompt,
        parseable: parseB
    }
    
    let diff = AUnique.length + BUnique.length

    newCompareData.push({noteID: noteID, note: AData.note.note_text, runA: runA, runB: runB, same: same.toString().replaceAll(",", ", "), diff: diff})
    return newCompareData

}
export const DataRunsTransformer={
    transformRuns,
    transformRunsDropdown,
    transformCompareData
}