import React, {useState} from "react";
import './components.css'
import { Modal, Button } from "react-bootstrap";

export default function EditNoteModal(props) {
    const [formData, setFormData] = useState({ //data typed into form
        newNote: props.selected[1],
        group: '',
        name: props.selected[2]+"-copy"
    })
    
    function handleFormChange({ target: { id, value } }) { //saves form data to state
        setFormData({ ...formData, [id]: value })
    }

    function handleRun(e) { // reformats and submits new note from form
        let finalNote = {
            name: formData.name,
            group_name: formData.group,
            note_text: formData.newNote
        }
        props.createNote(finalNote, false)
        props.handleEditClose()
    }
    
    
    return (
        <Modal dialogClassName="edit-modal" show={true} onHide={props.handleEditClose} >
            <Modal.Header  closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="inline-modal margin-bottom">
                    <div className="modal-title-text">
                        Create From Note
                    </div>
                    <div className="inline-modal flex-right-modal">
                    </div>
                </div>
                <textarea className="form-control text-area margin-bottom" value={formData.newNote} onChange={handleFormChange} id="newNote"></textarea>
                <div className="inline margin"> 
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Name this Note here" id="name" value={formData.name} onChange={handleFormChange}></input>
                    </span>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Group Name" value={formData.group} id="group" onChange={handleFormChange} ></input>
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-outline-primary" onClick={props.handleEditClose}>
                    Close
                </Button>
                <Button variant="primary"  onClick={handleRun}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}