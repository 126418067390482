
function transformNotes(notesList) {
    var newNotesList: any[] = []
    let transformedNotes = {
        notesList: notesList,
        tableNotes : newNotesList
    }
    if (notesList && notesList.length > 0) {
        notesList.forEach((value, i) => transformedNotes.tableNotes.push([value.uuid, value.note_text, value.name, value.group_name, value.created_at, "", value]))
    } else return []
    return transformedNotes
}


export const NotesTransformer = {
    transformNotes
}