import React, { useState, useMemo } from "react";
import CompareSummary from "./CompareSummary"
import { Button } from "react-bootstrap";
import './components.css'
import Pagination from "../../common/components/Pagination";
import CompareViewModal from "./CompareViewModal";


export default function CompareResults(props) {
    const fields = ["Note ID", "Variation A Keywords", "Variation B Keywords", "Diff"]
    const data = props.spreadsheetData

    const [currentPage, setCurrentPage] = useState(1)
    const [pageLength, setPageLength] = useState(10)
    const [showView, setShowView] = useState(false)

    const [currentView, setCurrentView] = useState({
        noteId: "",
        note: "",
        runA: { aiVendor: '', aiModel: '', total: '', keywords: '', unique: '', uniqueKeywords: '' },
        runB: { aiVendor: '', aiModel: '', total: '', keywords: '', unique: '', uniqueKeywords: '' },
        same: '',
        diff: ''
    },)

    const endIndex = 3

    const currentPageData = useMemo(() => {
        const firstIndex = (currentPage - 1) * pageLength;
        const lastIndex = firstIndex + Number(pageLength);
        return data.slice(firstIndex, lastIndex);
    }, [currentPage, pageLength, data])


    function handleViewClick(i) {
        setCurrentView(data[i])
        setShowView(true)
    }

    return (
        <div className=" " id="compare-results">
            <CompareSummary
                date={props.date}
                title="Compare Results"
                summaryType="Compare"
                data={data}
            ></CompareSummary>

            <div>
                <table className="compare-table">
                    <thead>
                        <tr>
                            {fields.map((field, i) => i === 0 || i === endIndex ? <th className="endCell" key={field}>{field}</th> : <th key={field}>{field}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData.map((currData, i) =>
                            <tr key={i}>
                                <td
                                    className="endCell">{currData.noteID}
                                    <br />
                                    <Button
                                        variant="btn btn-outline-primary"
                                        onClick={(e => handleViewClick(i))}>
                                        View
                                    </Button>
                                </td>

                                <td className="compare">
                                    <div className="compare-keywords-total">
                                        <b>Total: {currData.runA.total}</b>
                                        <div>{currData.runA.keywords}</div>
                                    </div>
                                    <div className="compare-keywords-unique">
                                        <b>Unique: {currData.runA.unique} </b>
                                        <div>{currData.runA.uniqueKeywords}</div>
                                    </div>
                                </td>

                                <td className="compare">
                                    <div className="compare-keywords-total">
                                        <b>Total: {currData.runB.total}</b>
                                        <div>{currData.runB.keywords}</div>
                                    </div>
                                    <div className="compare-keywords-unique">
                                        <b>Unique: {currData.runB.unique} </b>
                                        <div>{currData.runB.uniqueKeywords}</div>
                                    </div>
                                </td>
                                <td className="endCell"><b>{currData.diff}</b></td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {showView ?
                    <CompareViewModal
                        currentView={currentView}
                        handleCloseView={() => setShowView(false)}>
                    </CompareViewModal>
                : null}

                <Pagination
                    totalCount={data.length}
                    pageLength={pageLength}
                    onPageLengthChange={value => setPageLength(value)}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                ></Pagination>
            </div>
        </div>
    )
}
