import React, { useEffect, useState } from "react";
import './components.css'
import { Modal, Button } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import { domToReact } from 'html-react-parser';

export default function MultiView(props) {

    const [contrastColors, setContrastColors] = useState(false)
    const [rowArray, setRowArray] = useState([])

    useEffect(() => {
        let dataLength = props.selected.length
        let newRowArray = []

        for (let i = 0; i < dataLength; i += 2) {
            if (props.selected[i] && props.selected[i + 1]) {
                newRowArray.push([props.selected[i], props.selected[i + 1]])
            }
            else {
                newRowArray.push([props.selected[i]])
            }
        }
        setRowArray(newRowArray)
    }, [props])


    function getMarkupText(data) {
        let keywords = []

        let keywordsRaw = data.keywords.results

        let parse = true

        try {
            keywordsRaw.replace(/&quot;/ig, '"');
            keywords = JSON.parse(keywordsRaw).keywords
        } catch (error) {
            console.log(error)
            keywords = data.keywords.results
            parse = false
        }

        let markup = data.note.note_text

        if (parse) {
            keywords.forEach(element => {
                markup = markup.replace(element, `<span class="markup-text">` + element + `</span>`)
            });
        }

        return {markup: markup, parse: parse}
    }


    const options = {
        replace: ({ attribs, children }) => {
            if (!attribs) return;
            if (attribs.id === 'main') {
                return (
                    <h1 style={{ fontSize: 42 }}>{domToReact(children, options)}</h1>
                );
            } else if (attribs.class === 'markup-text') {
                return (
                    <span style={{ color: 'goldenrod' }}>
                        {domToReact(children, options)}
                    </span>
                );
            }
        }
    };

    function getCard(data) {
        let markupData = getMarkupText(data)
        let cardMarkupText = markupData.markup
        return (
            <div className="">
                <div>
                    <b>Result ID: </b>{data.uuid}<br/>
                    <b>Run ID: </b>{data.run_id}
                </div>
                <div>
                    <b>LLM: </b> {data.llm.vendor_name} {data.llm.engine_name}
                </div>
                <div className="">
                    <b>Prompt ID:</b>  {data.note.uuid}
                    <b> Prompt Title:</b>  {data.prompt.name} <br />
                    <b>Prompt Text:</b>  {data.prompt.prompt_text} <br />
                </div>
                <div className="">
                    <b>Note ID:</b>{data.note.uuid} <br />
                    <b>Note Title:</b>  {data.note.name} <br />
                    <b>Note Text:</b>{contrastColors ? HTMLReactParser(cardMarkupText, options) : data.note.note_text}<br />
                    {!markupData.parse && contrastColors? <div style={{color:"goldenrod"}}>Unable to Parse JSON</div>:null}
                </div>
                <div className="">
                    <b>Result:</b>  {data.keywords.results}  <br />
                </div>
                <div className="">
                    <b>Temperature:</b>  {data.parameters.temperature}
                    <b> Top P:</b>  {data.parameters.top_p}
                    <b> Max Tokens:</b>  {data.parameters.max_tokens}
                </div>
                <div>
                    <b>Date Created: </b>{data.created_at}
                </div>
            </div>
        )
    }

    function handleChangeContrastColors(){
        setContrastColors(!contrastColors)
    }

    return (
        <Modal dialogClassName="multiview-modal" show={true} onHide={props.handleHideView} >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-title-text">
                    View Runs
                </div>
                <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => handleChangeContrastColors()} /> 
                        Contrast Colors
                    </div>
                <table className="multiview-table">
                    <tbody>
                        {rowArray.map((row, i)=> 
                            <tr>
                                <td>
                                    {getCard(row[0])}
                                </td>
                                {row[1]?
                                <td>
                                    { getCard(row[1]) }
                                </td>
                                : null}
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-outline-primary" onClick={props.handleHideView}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}