import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Navbar } from "react-bootstrap";
import './signin.css'
// import '../common/common.css'
import { AuthService } from "../../common/services/AuthService.ts";

export default function SignIn() {

    const navigate = useNavigate();
    const authService = AuthService

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isRemember, setIsRemember] = useState(false)
    const [isError, setisError] = useState(false)

    function handleEmailChange(e) {
        setEmail(e.target.value)
    }
    function handlePasswordChange(e) {
        setPassword(e.target.value)
    }
    function handleCheckboxChange(e) {
        setIsRemember(!isRemember)
    }
    async function handleClick() {
        var auth = await authService.authorizeUser(email, password, isRemember)
        auth.error ? setisError(true) : navigate("/")
    }
    return (
        <div className="signInPage">
            <div className="nav-container-signin">
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Navbar.Brand className="nav-title">LLM Tester</Navbar.Brand>
                </Navbar>
            </div>

            <div className="d-flex justify-content-center">
                <div className="signinBox">
                    <form>
                        <h1 className="signin">First, Sign in...</h1>
                        <label htmlFor="email">Email</label>
                        <input type="email" autoComplete="username" className="form-control" id="email" placeholder="Enter your email" style={{ "marginBottom": "10px" }} onChange={handleEmailChange} />
                        <label htmlFor="password">Password</label>
                        <input type="password" autoComplete="current-password" className="form-control" id="password" placeholder="Password" style={{ "marginBottom": "10px" }} onChange={handlePasswordChange} />
                        <input className="form-check-input" type="checkbox" value="true" id="isRemember" checked={isRemember} onChange={handleCheckboxChange} />
                        <label className="form-check-label" htmlFor="isRemember" style={{ "marginBottom": "10px" }}>
                            Remember me
                        </label>
                        <br></br>
                        <Button className="signin" onClick={handleClick}>Sign In</Button>
                        {isError ? <div className="alert alert-danger" role="alert">Wrong username or password</div> : null}
                    </form>
                </div>
            </div>
        </div>
    )
}