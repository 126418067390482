import React, { useEffect, useState } from "react";
import { Button, DropdownButton, Dropdown, Alert } from "react-bootstrap";
import { Trash, Download } from "react-bootstrap-icons";
import './components.css'

export default function ResultSummary(props) {
    const [edit, setEdit] = useState(false)

    const id = props.data.uuid
    const type = props.data.type
    const notes = props.data.noteCount

    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [editError, setEditError] = useState("")

    const ranks = [
        { id: "Unranked", color: "#212529" },
        { id: "Poor", color: "#DC3545" },
        { id: "Good", color: "#E9AF00" },
        { id: "Great", color: "#20C997" }
    ]
    const [rankStyle, setRankStyle] = useState("#6C757D")

    const fields = [
        { id: "Vendor", value: props.data.aiVendor },
        { id: "Engine", value: props.data.aiModel },
        { id: "Temperature", value: props.data.temp},
        { id: "Max Tokens", value: props.data.tokens !== null? props.data.tokens : "---" },
        { id: "Top P", value: props.data.topP !== null? props.data.topP : "---" }
    ]

    const [formData, setFormData] = useState({
        run_name: props.data.runName,
        grade: props.data.grade? props.data.grade: "",
        rank: props.data.rank? props.data.rank: "",
    })
    useEffect(()=>{
        const ranks = [
            { id: "Unranked", color: "#212529" },
            { id: "Poor", color: "#DC3545" },
            { id: "Good", color: "#E9AF00" },
            { id: "Great", color: "#20C997" }
        ]
        ranks.forEach(element=>{
            if(element.id===formData.rank){
                setRankStyle(element.color)
            }
        })
    },[formData.rank])

    useEffect(()=>{
        if(props.editError){
            setEditError(props.editError)
            setSaving(false)
            setSaved(false)
        }
    },[props.editError])

    function handleFormChange({ target: { id, value } }) {
        setFormData({ ...formData, [id]: value })
        setEdit(true)
    }
    function handleRankChange(i) {
        setFormData({ ...formData, rank: ranks[i].id })
        setEdit(true)
    }
    function handleSetPassFail(key){
        setFormData({...formData, grade: key})
        setEdit(true)
    }
    function handleSave(){
        setSaving(true)
        props.handleSaveEdit(formData, props.data.uuid)
        setSaving(false)
        setSaved(true)
    }

    return (
        <div className="summary-section">

            <span className="summary-right">

                <div className="summary-right-top ">
                    <div>
                        Run ID: <b>{id}</b>
                    </div>
                    <div>
                        Type: <b>{type}</b>
                    </div>
                    <div>
                        Notes: <b>{notes}</b>
                    </div>
                </div>

                <div className="summary-right-middle">
                    {fields.map((field) => <div key={field.id}><b>{field.id}:</b> {field.value}</div>)}
                </div>

                <div>
                    <b>Date:</b> {props.data.createdDate}
                    <br></br>
                    <b>Source:</b> {props.data.note !== '' ? "Note Entry" : props.data.group}
                </div>
            </span>

            <div className="summary-left ms-auto">
                <div className="inline">
                    <DropdownButton className={formData.grade ? "medium selected": "medium"} value={formData.grade} title={<div className="dropdown-title">{formData.grade ? formData.grade : "Pass/Fail"}</div>} id="passFail" onSelect={handleSetPassFail}>
                            <Dropdown.Item eventKey="Pass" value="Pass" key="pass">Pass</Dropdown.Item>
                            <Dropdown.Item eventKey="Fail" key="fail">Fail</Dropdown.Item>
                    </DropdownButton>
                    {formData.grade && formData.grade === "Pass" ? 
                        <DropdownButton style={{"--new-color": rankStyle}} className="medium vary-color" value={formData.rank} title={formData.rank ? formData.rank : "Rank"} onSelect={handleRankChange}>
                            {ranks.map((rank, i) => <Dropdown.Item  style={{color:rank.color}} eventKey={i} key={i} className={rank.color}>{rank.id}</Dropdown.Item>)}
                        </DropdownButton>
                    :null}

                    {edit? <Button onClick={handleSave}>Save</Button> :null}
                    {saving && !saved? <div className="save-notif">Saving...</div> : !saving && saved? <div className="save-notif">Saved!</div>: null}
                    {!saving && !saved && editError? <Alert variant="danger save-error">{editError.message? editError.message: "Error"}</Alert> :null}

                    <div className="flex-right">
                        <Button type="button" variant="outline-danger" onClick={() => props.deleteTest()}>Delete <Trash /></Button>
                        <Button className="button-icon" variant="outline-primary">Export <Download /></Button>
                    </div>

                </div>

                <div className="inline margin-bottom">
                    <span className="text-input-container">Test Name:</span>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control" placeholder="Name this test here" id="run_name" value={formData.run_name} onChange={handleFormChange}></input>
                    </span>
                </div>

                <div className="summary-prompt-box">
                    <b>Prompt: </b><br></br>
                    {props.data.instruction}
                </div>

            </div>

        </div>

    )
}