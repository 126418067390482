import React from "react"
import './views.css' //TODO: edit css file
import SignIn from "../components/SignIn"
import Copyright from "../../common/components/Copyright"

export default function Login(){
    return(
        <div className="app-container">
            <div className="signin-container">
                <SignIn/>
            </div>
            <Copyright/>
        </div>
    )
}