import React, { useEffect, useState } from "react";
import Spreadsheet from "../../common/components/Spreadsheet";
import './components.css'
import { Eye, Trash, FilterSquare } from "react-bootstrap-icons";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import ViewRunModal from "./ViewRunModal.js";
import MultiView from "./MultiView.js";

export default function DataRunsResults(props) {

    const fields = [{ name: "Run ID", hidden: true }, { name: "Name", hidden: false },
    { name: "Type", hidden: false }, {name: "Group", hidden: false }, { name: "Rank", hidden: false }, { name: "Prompt", hidden: false },
    { name: "Engine", hidden: false }, { name: "Notes", hidden: false }, { name: "Date", hidden: false }, { name: "data", hidden: true }]


    const typeList = [{ label: "All", value: "all" }, { label: "Keyword", value: "keywords" }, { label: "Topic", value: "topics" },
    { label: "Sentiment", value: "sentiment" }, { label: "Emotion", value: "emotions" }]

    const csvFields = ["Run Name", "UUID", "Type", "Temperature", "Top P", "Max Tokens",
        "LLM", "Prompt ID", "Prompt Name", "Prompt", "Note ID", "Note Name", "Note", "Results", "Grade", "Rank"]

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [selectCount, setSelectCount] = useState(0)
    const [filterType, setFilterType] = useState("All")
    const [showViewModal, setShowViewModal] = useState(false)
    const [showMultiView, setShowMultiView] = useState(false)
    const [selected, setSelected] = useState([])
    const [currentView, setCurrentView] = useState()

    const [message, setMessage] = useState("")

    const [clear, setClear] = useState()

    const [groupsList, setGroupsList] = useState([])
    const [groupFilter, setGroupFilter] = useState()

    useEffect(() => {
        setData(props.tableData)
    }, [props])

    function getSelected(selected, count) {
        setSelected(selected)
        setSelectCount(count)
    }

    function handleSetFilter(key, { target: { text } }) {
        let type

        switch (text) {
            case "Keyword":
                type = { label: "Keyword", value: "keywords" }
                break;
            case "Topic":
                type = { label: "Topic", value: "topics" }
                break;
            case "Sentiment":
                type = { label: "Sentiment", value: "sentiment" }
                break;
            case "Emotion":
                type = { label: "Emotion", value: "emotions" }
                break;
            default:
                type = { label: "All", value: "all" }
                break;
        }
        setFilterType(type)
    }

    function getSelectedIds() {
        let idArray = []
        selected.forEach(element => idArray.push(element[0]))
        return idArray
    }

    useEffect(() => {
        var newFilteredData = []
        var filterByType = true
        var filterByGroup = true

        if (!filterType || !filterType.value || filterType.value === "all") {
            filterByType = false
        }
        if (!groupFilter || groupFilter === "All Groups") {
            filterByGroup = false
        }

        if (!filterByType && !filterByGroup) {
            newFilteredData = data
        }
        else if (filterByType && !filterByGroup) {
            data.forEach(element => {
                if (element[2] === filterType.value) {
                    newFilteredData.push(element)
                }
            });
        }
        else if (filterByGroup && !filterByType) {
            data.forEach(element => {
                if (element[9].note.group_name && element[9].note.group_name.toString() === groupFilter) {
                    newFilteredData.push(element)
                }
            });
        }
        else if (filterByGroup && filterByType) {
            data.forEach(element => {
                if ((element[9].note.group_name && element[9].note.group_name.toString() === groupFilter) && (element[2] === filterType.value)) {
                    newFilteredData.push(element)
                }
            });
        }
        setFilteredData(newFilteredData)
    }, [data, filterType, groupFilter])


    useEffect(() => {
        var newGroupsList = []
        var currGroup
        if (data) {
            data.forEach(element => {
                if (element[9].note.group_name) {
                    currGroup = element[9].note.group_name.toString() //this needs to change 
                    if (!newGroupsList.includes(currGroup)) {
                        newGroupsList.push(currGroup)
                    }
                }

            });
            newGroupsList.push("All Groups")
            setGroupsList(newGroupsList)
        }
    }, [data])

    function handleSetGroupFilter(key, { target }) {
        setGroupFilter(target.text)
    }

    async function handleShowView() {
        if (selectCount === 1) {
            let runData = selected[0][selected[0].length - 1]
            setCurrentView(runData)
            setShowViewModal(true)
        }
        else if (selectCount > 1) {
            let runData = []


            for (let i = 0; i < selected.length; ++i) {
                if (selected[i]) {
                    runData.push(selected[i][selected[i].length - 1])
                    setCurrentView(runData)
                    setShowMultiView(true)
                }
            }
        }
    }

    function handleHideView() {
        setShowViewModal(false)
        setShowMultiView(false)
    }

    useEffect(() => {
        if (props.deleteResponse && props.deleteResponse.message) {
            setMessage("something went wrong: " + props.deleteResponse.message)
        }
        else if (props.deleteResponse !== undefined) {
            setMessage("deleted")

            setTimeout(() => {
                setMessage("")
            }, 1000)
        }
    }, [props.deleteResponse])

    function deleteRun() {
        setMessage("deleting...")
        selected.forEach(element => {
            try {
                props.deleteRun(element[0])
            } catch (error) {
                console.log(error)
            }
        })

        setClear(Date.now)
        setSelected([])
        setSelectCount(0)
    }

    function downloadResults() {
        let results

        if (selected.length !== 0) {
            let selectedResults = []
            let idArray = getSelectedIds()

            props.csvData.forEach(element => {
                if (idArray.includes(element[1])) {
                    selectedResults.push(element)
                }
            })

            results = selectedResults
        }
        else {
            results = props.csvData
        }

        var csvData = csvFields.join(",")
        csvData += "\n"
        let currentCell

        results.forEach(row => {
            for (let i = 0; i < csvFields.length; ++i) {
                currentCell = row[i] !== null ? row[i] : ` `
                currentCell = currentCell.replace(/["]+/g, `""`)
                currentCell = `"` + currentCell + `"`
                csvData += currentCell
                if (i !== csvFields.length - 1) {
                    csvData += ","
                }
            }
            csvData += "\n"
        })

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.setAttribute('href', objUrl)
        link.setAttribute('download', 'results.csv')
        document.querySelector('body').append(link)

        link.click()
    }

    return (
        <div>
            <h2>Data Runs</h2>
            <div>
                {showViewModal ? <ViewRunModal selected={currentView} handleHideView={handleHideView} /> : null}

                {showMultiView ? <MultiView selected={currentView} handleHideView={handleHideView} /> : null}
                <div className="filters-section inline">
                    <div className="inline">
                        {selectCount === 0 ?
                            <div className="inline">
                                {/* <Button className="compare-button" disabled>Compare</Button> */}
                                <Button className="compare-button" onClick={() => downloadResults()}> Export</Button>
                                <div className="button-link deactivated"><Eye /> View</div>
                                <div className="button-link deactivated"><Trash /> Delete</div>
                            </div>
                            : selectCount === 1 ?
                                <div className="inline">
                                    {/* <Button className="compare-button"> Compare</Button> */}
                                    <Button className="compare-button" onClick={() => downloadResults()}> Export</Button>
                                    <div className="button-link" onClick={() => handleShowView()}><Eye /> View</div>
                                    <div className="button-link danger" onClick={() => deleteRun()}><Trash /> Delete</div>
                                </div>
                                : <div className="inline">
                                    {/* <Button className="compare-button"> Compare</Button> */}
                                    <Button className="compare-button" onClick={() => downloadResults()}> Export</Button>
                                    <div className="button-link" onClick={() => handleShowView()}><Eye /> View</div>
                                    <div className="button-link danger" onClick={() => deleteRun()}><Trash /> Delete</div>
                                </div>
                        }

                        <div className="save-notif">{message}</div>
                    </div>

                    <div className=" inline ms-auto">
                        <Button variant="btn btn-link-secondary"><FilterSquare></FilterSquare></Button>
                        <DropdownButton className={groupFilter ? 'medium selected' : "medium"} title={groupFilter ? groupFilter : "Select Group"} onSelect={handleSetGroupFilter}>
                            {groupsList.map((group, i) => <Dropdown.Item key={i} value={group.value} id="group">{group}</Dropdown.Item>)}
                        </DropdownButton>
                        <DropdownButton className={filterType && filterType.value ? "medium selected" : "medium"} title={<div className="dropdown-title">{filterType && filterType.label ? filterType.label : "Filter by Type"}</div>} id="type" onSelect={handleSetFilter}>
                            {typeList.map((type, i) => <Dropdown.Item key={i} id="type">{type.label}</Dropdown.Item>)}
                        </DropdownButton>
                    </div>
                </div>
                <Spreadsheet
                    fields={fields}
                    data={filteredData}
                    isSelectable={true}
                    type="dataRun"
                    getSelected={getSelected}
                    defaultSort={8}
                    clear={clear}
                ></Spreadsheet>
            </div>
        </div>
    )
}