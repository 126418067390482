import React, {useState, useEffect} from "react"
import Copyright from "../../../common/components/Copyright"
import './views.css'
import NavBar from "../../../common/components/NavBar"
import {NotesService} from "../services/NotesService.ts"
import NewNote from "../components/NewNote.js"
import NoteResults from "../components/NoteResults.js"

export default function Notes() {
    
    const notesService = NotesService
    const [notesList, setNotesList]= useState([])
    const [results, setResults] = useState()

    const [deleteResponse, setDeleteResponse] = useState()

    function getData(){
        notesService.getNotes().then(value => setNotesList(value))
    }

    useEffect(()=> {
        if(notesList.length === 0 ){
            getData()
        }
    })

    function deleteNote(uuid){
        notesService.deleteNote(uuid).then(response=>{
            setDeleteResponse(response)
            getData()
        })
    }

    
    function createNote(data, isCsv){
        if(isCsv){
            console.log(data)
            notesService.uploadCSV(data).then(value=> {
                setResults(value)
                getData()
            })
        }
        else{
            notesService.createNote(data).then(value=> {
                setResults(value)
                getData()
            })
        }
    }
    
    function getFullNote(noteID){
        console.log(noteID)
    }

    return (
        <span>
            <div className="pageContainer">
                <NavBar showDatabaseBar={true}/>
                <div className="component-container">
                    <div className="form-box-left">
                    <NewNote
                    results={results}
                    createNote = {createNote}
                    />
                     </div>
                    <NoteResults
                        data = {notesList}
                        getFullNote = {getFullNote}
                        deleteNote={deleteNote}
                        deleteResponse={deleteResponse}
                        createNote = {createNote}
                    ></NoteResults>                   
                </div>
            </div>
            <Copyright />
        </span>
    )
}
