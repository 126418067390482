import React, { useEffect, useState } from "react";
import ResultSummary from "./ResultSummary"
import Spreadsheet from "../../common/components/Spreadsheet";
import { Alert } from "react-bootstrap";
import './components.css'

export default function TestResults(props) {
    const title = "Test Results"
    const fields = [{name:"Note ID", hidden:false}, {name:"Content", hidden:false}, {name:"Keyword", hidden:false}]

    // const runData = props.runData
    const [spreadsheetData, setSpreadsheetData] = useState([])
    const [runData, setRunData] = useState()
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(()=> {
        let newSpreadsheetData = []
        if(props && props.resultData && props.resultData[0]===null){
            setIsError(true)
            setErrorMessage("No Data Received")
        }
        else if(props && props.resultData && props.resultData[0] && props.resultData[0].message){
            setIsError(true)
            setErrorMessage(props.resultData[0].message)
        }
        else if(props && props.resultData && props.resultData[0] && props.resultData[0][0].uuid && spreadsheetData.length===0){ // move this to a transformer
            props.resultData[0].forEach(result =>{ //here?
                newSpreadsheetData.push([result.note.uuid, result.note.note_text, result.keywords.results])
            })
            setSpreadsheetData(newSpreadsheetData)
        }
    },[spreadsheetData, props])

    useEffect(()=>{
        let newRunData = ""
        if(props && props.resultData && props.resultData[0]===null){
            setIsError(true)
            setErrorMessage("No Data Received")
        }
        else if(props && props.resultData && props.resultData[0] && props.resultData[0].message){
            setIsError(true)
            setErrorMessage(props.resultData[0].message)
        }
        else if(props && props.resultData && !props.resultData[0].messsage && props.resultData[0][0] && !runData){
            newRunData = {
                runName: props.resultData[0][0].run_name, 
                uuid: props.resultData[0][0].uuid,
                type: props.formData.type, 
                aiVendor: props.resultData[0][0].llm.vendor_name, 
                aiModel: props.resultData[0][0].llm.engine_name, 
                note: props.resultData[0][0].note.note_text, 
                group: props.resultData[0][0].note.group_name, 
                instruction: props.resultData[0][0].prompt.prompt_text,
                temp: props.resultData[0][0].parameters.temperature, 
                tokens: props.resultData[0][0].parameters.max_tokens, 
                topP: props.resultData[0][0].parameters.top_p,
                createdDate: props.resultData[0][0].created_at,
                noteCount: props.resultData.length,
                grade:props.resultData[0][0].grade,
                rank: props.resultData[0][0].rank
            } 
            setRunData(newRunData)
        }
    },[runData, props])
    
    return (
        <div>
        {isError ? <Alert variant="danger">Something went wrong: {errorMessage}</Alert> : 
        <div className=" ">
            <h2>Test Results</h2>
            {runData ?
                <ResultSummary
                    title={title}
                    summaryType="Run"
                    data={runData}
                    handleSaveEdit={props.handleSaveEdit}
                    editError={props.editError}
                    deleteTest={props.deleteTest}
                ></ResultSummary>
                : null}
            {spreadsheetData.length>0 ?
                <Spreadsheet
                    fields={fields}
                    data={spreadsheetData}
                    isSelectable={false}
                ></Spreadsheet>
                : null}
        </div>}
        </div>
    )
}