import React, { useEffect, useState } from "react"
import NavBar from "../../../common/components/NavBar"
import Copyright from "../../../common/components/Copyright"
import './views.css'
import PromptResults from "../components/PromptResults.js"
import NewPrompt from "../components/NewPrompt.js"
import { PromptService } from "../services/PromptService.ts"

export default function Prompts() {
    const promptService = PromptService
    const [promptsList, setPromptsList] = useState([])
    const [results, setResults] = useState()
    const [deleteResponse, setDeleteResponse] = useState()

    function getData(){
        promptService.getPrompts().then(value => setPromptsList(value))
    }

    useEffect(() => {
        if(promptsList.length === 0){
            getData()
        }
    })

    function deletePrompt(uuid){
        promptService.deletePrompt(uuid).then(response=>{
            setDeleteResponse(response)
            getData()
        })
    }

    function createPrompt(data) {
        promptService.createPrompt(data).then(value => {
            setResults(value)
            getData()
        })
    }

    return (
        <span>
            <div className="pageContainer">
                <NavBar showDatabaseBar={true} />
                <div className="component-container">
                    <div className="form-box-left">
                        <NewPrompt
                            createPrompt={createPrompt}
                            results={results}
                        ></NewPrompt>
                    </div>

                    <PromptResults
                        data={promptsList}
                        createPrompt={createPrompt}
                        deletePrompt={deletePrompt}
                        deleteResponse={deleteResponse}
                    ></PromptResults>
                </div>
            </div>
            <Copyright />
        </span>
    )
}
