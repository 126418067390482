import React, {useState} from "react";
import './components.css'
import { Modal, Button,Dropdown, DropdownButton } from "react-bootstrap";

export default function EditPromptModal(props) {
    const typeList = ["Keyword", "Topic", "Sentiment", "Emotion"]
    const [formData, setFormData] = useState({
        prompt_text: props.selected[2],
        type: props.selected[3],
        name: props.selected[1] + "-copy",
    })

    function handleFormChange({ target: { id, value } }) {
        setFormData({ ...formData, [id]: value })
    }

    function handleSetType(key, { target: { text } }) {
        setFormData({ ...formData, type: text })
    }

    function handleRun(e) {
        const finalPrompt = {
            "name": formData.name,
            "function": formData.type,
            "prompt_text": formData.prompt_text
        }
        props.createPrompt(finalPrompt)
        props.handleEditClose()
    }

    return (
        <Modal dialogClassName="edit-modal" show={true} onHide={props.handleEditClose} >
            <Modal.Header  closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="inline-modal margin-bottom">
                    <div className="modal-title-text margin-bottom">
                    Create From Prompt
                    </div>
                    <textarea className="form-control text-area tall margin-bottom" placeholder="Enter Prompt" id="prompt_text" value={formData.prompt_text} onChange={handleFormChange}></textarea>
                <div className="inline">
                    <DropdownButton className={formData.type ? "medium selected" : "medium"} title={<div className="dropdown-title">{formData.type ? formData.type : "Select Type"}</div>} id="type" onSelect={handleSetType}>
                        {typeList.map((type, i) => <Dropdown.Item key={i} value={type} id="type">{type}</Dropdown.Item>)}
                    </DropdownButton>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Name this Prompt here" id="name" onChange={handleFormChange} value={formData.name}></input>
                    </span>
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-outline-primary" onClick={props.handleEditClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleRun}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}