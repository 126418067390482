import axios from "axios"
import { AuthService } from "../../../common/services/AuthService.ts"
import { PromptTransformer } from "../transformers/PromptTransformer.ts";

const authservice = AuthService
const transformer = PromptTransformer

async function getPrompts() {
    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/prompts/', //replace dev url with enviroment variable
        headers: {
            'Authorization': token
        }
    };

    var promptsList

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    promptsList = transformer.transformPrompts(results)
    return promptsList
}
async function getPromptById(id) {
    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/prompts/' + id,
        headers: {
            'Authorization': token
        }
    };

    var prompt

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    prompt = results
    return prompt
}
async function createPrompt(newPrompt) {

    var type

    switch (newPrompt.function) {
        case "Keyword":
            type = "keywords"
            break;
        case "Topic":
            type = "topics"
            break;
        case "Sentiment":
            type = "sentiment"
            break;
        case "Emotion":
            type = "emotions"
            break;
        default:
            type = ""
            break;
    }

    var data = JSON.stringify({"name":newPrompt.name ,"function": type ,"prompt_text":newPrompt.prompt_text});

    var token = authservice.getToken()

    var results = {success: false, message: '', data: ''}

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/prompts/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: data
    };
        await axios(config)
            .then(function (response) {
                results.success = true
                results.message = "Prompt added successfully"
                results.data = response.data
                return response.data
            })
            .catch(function (error) {
                results.success = false
                results.message = error.message
                results.data = error.response.statusText
                console.log(error)
                return error
    });
    return results
}

async function deletePrompt(uuid) {
    var token = authservice.getToken()

    var config = {
        method: 'delete',
        url: 'https://dev.toself.io/v1/prompts/' + uuid,
        headers: {
            'Authorization': token
        }
    };

    var prompt

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    prompt = results
    return prompt
}

export const PromptService = {
    getPrompts,
    getPromptById,
    createPrompt,
    deletePrompt
}