import React, { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import './components.css'
import { AuthService } from "../services/AuthService.ts" 
import { Navbar } from "react-bootstrap";
import { ChatLeftText, PencilSquare, CaretDownFill, CaretUpFill, CodeSquare } from "react-bootstrap-icons";
import { Transition } from "react-transition-group";

export default function NavBar(props) {
    const navigate = useNavigate();
    const authService = AuthService
    function handleSignOut() {
        authService.signOut()
        navigate("/login")
    }

    const [showDatabaseBar, setShowDatabaseBar] = useState(props.showDatabaseBar ? props.showDatabaseBar : false)
    const [databaseHover, setDatabaseHover] = useState(false)
    const [databaseClick, setDatabaseClick] = useState(false)
    const [tabHover, setTabHover] = useState(false)

    useEffect(() => {
        if (databaseClick || databaseHover || tabHover) {
            setShowDatabaseBar(true)
        }
        else {
            setShowDatabaseBar(false)
        }
    },[databaseClick, databaseHover, tabHover])

    const transitionStyles = {
        entering: { height: "41.5px", },
        entered: { height: "41.5px", },
        exiting: { height: "0px" },
        exited: { height: "0px" },
    };

    return (
        <div className="nav-container">
            <div className="nav">
                <Navbar.Brand className="nav-title">LLM Tester</Navbar.Brand>

                <NavLink className="nav" to={"/"} >New Test</NavLink>
                <NavLink className="nav" to={"/compare"}>Compare</NavLink>
                <NavLink className="nav" to={"/dataruns"}>Data Runs</NavLink>
                <div className={showDatabaseBar ? "database-link-active database-link" : "database-link"}
                    onClick={e => setDatabaseClick(!databaseClick)}
                    onMouseOver={e => setDatabaseHover(true)}
                    onMouseLeave={e => setDatabaseHover(false)}
                >Database {showDatabaseBar ? <CaretUpFill size="10px" /> : <CaretDownFill size="10px" />} </div>
                <span className="sign-out ms-auto" onClick={handleSignOut}>Sign Out</span>
            </div>
            <Transition in={showDatabaseBar} timeout={600}>
                {state => (
                    <div className="database-nav shadow" style={{
                        ...transitionStyles[state]
                    }}
                        onMouseOver={e => setTabHover(true)}
                        onMouseLeave={e => setTabHover(false)}>
                        <div className="sub-link-container">
                            <NavLink className="nav" to={"/database/notes"}><PencilSquare /> Notes</NavLink>
                            <NavLink className="nav" to={"/database/prompts"}><ChatLeftText /> Prompts</NavLink>
                            <NavLink className="nav " to={"/database/llms"}><CodeSquare /> LLMs</NavLink>
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    )
}