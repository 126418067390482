import React, { useState, useEffect } from "react";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import './components.css'
import { XCircleFill } from "react-bootstrap-icons";

export default function NewCompare(props) {
    const [formData, setFormData] = useState({
        type: '',
        runA: '',
        runB: '',
        note: '',
        group: '',
    })

    const typeList = ["Keyword", "Topic", "Sentiment", "Emotion"]
    const [isComplete, setIsComplete] = useState(false)
    const [notesList, setNotesList] = useState([])
    const [groupsList, setGroupsList] = useState([])
    const [noteKey, setnoteKey] = useState([Date.now()])
    const [runList, setRunList] = useState([])
    const [fullRunList, setFullRunList]= useState([])

    useEffect(() => {
        if(props.dropdownData && props.dropdownData.notesList && props.dropdownData.notesList.length !== 0){
            setNotesList(props.dropdownData.notesList)
            setGroupsList(props.dropdownData.groupsList)
        }
        if(props.runsDropdown && props.runsDropdown.length > 0){
            setFullRunList(props.runsDropdown)
        }
    },[props.dropdownData,  props.runsDropdown])

    useEffect(() => {
        if (formData.type && (formData.group || formData.note) && formData.runA && formData.runB) {
            setIsComplete(true)
        } else setIsComplete(false)
    }, [formData]);

    useEffect(()=>{
        if(formData.note){
            let newRunList = []
            let noteID = JSON.parse(formData.note.value).uuid
            fullRunList.forEach(element => {
                if(noteID === element.noteID){
                    newRunList.push({dropdown: element.dropdown, resultID: element.resultID, value:JSON.stringify(element)})
                }
            });
            setRunList(newRunList)
        }
    },[formData.note, fullRunList])

    function handleFormChange(key, {target}){
        let newObject = {dropdown: target.text, value: target.getAttribute("value")}
        setFormData({ ...formData, [target.id]: newObject })
    }
    function handleRun(e) {
        props.onCompare(formData)
    }
    function handleNoteClear() {
        setFormData({ ...formData, note: '', group: '' })
        setnoteKey(Date.now())
    }
    function handleClear(e) {
        props.handleClear()
    }

    

    return (
        <div className=" ">
            <span className="form-box-left">
                <span className="form-section">
                    <h4>1. Subject Type</h4>
                    <div className="inline margin">
                        <DropdownButton className={formData.type ? "medium selected": "medium"}  title={<div className="dropdown-title">{formData.type.dropdown ? formData.type.dropdown : "Select Type"}</div>} id="type" onSelect={handleFormChange}>
                            {typeList.map((type, i) => <Dropdown.Item key={i} value={type} id="type">{type}</Dropdown.Item>)}
                        </DropdownButton>
                    </div>

                    <h4>2. Source</h4>
                    {formData.note === '' && formData.group === '' ?
                        <div className="inline margin" key={noteKey}>
                            <DropdownButton className={formData.note ? "large selected": "large"} title={formData.note.dropdown ? formData.note.dropdown : "Select Note"} id="note" onSelect={handleFormChange}>
                                {notesList.map((note, i) => <Dropdown.Item key={i} value={note.value} id="note">{note.dropdown}</Dropdown.Item>)}
                            </DropdownButton>
                            <DropdownButton className={formData.group ? "large selected": "large"} title={formData.group.dropdown ? formData.group.dropdown : "Select Group"} id="group" onSelect={handleFormChange}>
                                {groupsList.map((group, i) => <Dropdown.Item key={i} value={group.value} id="group">{group.dropdown}</Dropdown.Item>)}
                            </DropdownButton>
                        </div>

                        : formData.note ?
                            <div className="inline margin" key={noteKey}>
                                <DropdownButton className={formData.note ? "large selected": "large"}  title={<div className="dropdown-title">{formData.note.dropdown}</div>} id="note" onSelect={handleFormChange}>
                                    {notesList.map((note, i) => <Dropdown.Item key={i} value={note.value} id="note">{note.dropdown}</Dropdown.Item>)}
                                </DropdownButton>
                                <div className="blue icon" onClick={handleNoteClear}> Reset <XCircleFill /></div>
                            </div>

                            :
                            <div className="inline margin" key={noteKey}>
                                <DropdownButton className={formData.group ? "large selected": "large"} title={<div className="dropdown-title">{formData.group.dropdown}</div>} id="group" onSelect={handleFormChange}>
                                    {groupsList.map((group, i) => <Dropdown.Item key={i} value={group.value} id="group">{group.dropdown}</Dropdown.Item>)}
                                </DropdownButton>
                                <div className="blue icon" onClick={handleNoteClear}> Reset <XCircleFill /></div>
                            </div>
                    }

                    <div className="inline">
                        <span>
                            <h4>3A. Run A</h4>
                            <DropdownButton className={formData.runA ? "large margin selected": "large margin"} title={<div className="dropdown-title">{formData.runA.dropdown ? formData.runA.dropdown : "Test Results A"}</div>} id="runA" onSelect={handleFormChange}>
                                {runList.map((run, i) => {if(!formData.runB.dropdown || (JSON.parse(formData.runB.value).resultID  !== run.resultID)){return <Dropdown.Item key={i} value={run.value} id="runA">{run.dropdown}</Dropdown.Item>} else{return "null"}})}
                            </DropdownButton>
                        </span>
                        <span>
                            <h4>3B. Run B</h4>
                            <DropdownButton className={formData.runB ? "large margin selected": "large margin"} title={<div className="dropdown-title">{formData.runB.dropdown ? formData.runB.dropdown : "Test Results B"}</div>} id="runB" onSelect={handleFormChange}>
                            {runList.map((run, i) => {if(!formData.runA.dropdown || (JSON.parse(formData.runA.value).resultID !== run.resultID)){return <Dropdown.Item key={i} value={run.value} id="runB">{run.dropdown}</Dropdown.Item>} else{return null}})}
                            </DropdownButton>
                        </span>
                    </div>


                </span>
                <div className="run">
                    <Button className="button-run" disabled={!isComplete} onClick={handleRun}>Compare Results</Button>
                    <Button className="button-run" variant="btn btn-outline-primary" onClick={handleClear}>Clear All</Button>
                </div>
            </span>
            <span className="directions">
                <h2>Compare</h2>
                <h5>How-To:</h5>
                <ol style={{ paddingLeft: "20px", marginTop: "10px" }}>
                    <li><span className="blue">Type: </span>Type: Select the type of test (Keywords, Topics, etc)</li>
                    <li><span className="blue">Source: </span>Select a Note or Group from the database</li>
                    <li><span className="blue">Runs: </span>Select two Run results from the database to compare</li>
                </ol>
                <div>When all the results are filled out, press <span className="blue">Compare Results</span></div>
                <div>To start over or clear the form, press <span className="blue">Clear All.</span></div>
            </span>
        </div>
    )
}