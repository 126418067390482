import axios from "axios"
import Cookies from "js-cookie";

async function authorizeUser(email, password, isRemember) {

    var data = JSON.stringify({ "username": email, "password": password});

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/auth/token', //TODO: replace with variable
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const results =
       await axios(config)
        .then(function (response) {
            setToken(response.data.access_token, isRemember)
            return response.data
        })
        .catch(function (error) {
            console.log(error);
            return {error: true}
        });

   return results
}

function setToken(userToken, isRemember) {
    var token = `Bearer ` + userToken
    if (isRemember){
        Cookies.set('token', token, { expires: 365})    
    }
    else{
        Cookies.set('token', token, )    
    }
}

function getToken() {
    var tokenString = Cookies.get('token') 
    tokenString = tokenString? tokenString.replace(/['"]+/g, '') :null
    return tokenString
} 

function signOut() {
    Cookies.remove('token')
    sessionStorage.clear()
}
window.onbeforeunload = function() {
    localStorage.clear();
 }

export const AuthService = {
    signOut,
    authorizeUser,
    getToken
}