import React, { useState, useEffect, useRef } from "react";
import { Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import './components.css'
import { FiletypeCsv, XCircleFill } from "react-bootstrap-icons";

export default function NewNote(props) {
    const [formData, setFormData] = useState({ //data typed into form
        newNote: '',
        group: '',
        name: ''
    })
    const [useCustomName, setUseCustomName] = useState(false) //prevents typed in name from being overwritten by auto generated name
    const [isCsv, setIsCsv] = useState(false) //true if a csv has been uploaded
    const [csvError, setCsvError] = useState(false); //is true if file is not a csv
    const [file, setFile] = useState() //uploaded csv file
    const [isComplete, setIsComplete] = useState(false) //whether the form is complete
    const [results, setResults] = useState() //results from backend after submitting
    const [refKey, setRefKey] = useState(Date.now) //key to reset ref when csv is removed

    const fullDate = new Date()
    const date = fullDate ? fullDate.getFullYear().toString() + (fullDate.getMonth() + 1).toString().padStart(2, "0") + fullDate.getDate().toString()
        : null;

    const hiddenFileInput = useRef(null); //ref for uploading file

    function handleFormChange({ target: { id, value } }) { //saves form data to state
        if (id === "name") {
            setUseCustomName(true)
        }
        setFormData({ ...formData, [id]: value })
    }

    function handleBlur() { //triggers auto name generation after user leaves note text box
        if (!useCustomName) {
            setFormData({ ...formData, name: generateName() })
        }
    }

    function generateName() { //auto generates note name 
        if(formData.newNote !== ""){
            let name = formData.newNote
            let newName = name.split(' ').slice(0, 5).join("-")
            name = newName.replace(/[.,#!$%^&*;:{}=_`~() ]/g, "")
            newName = date + "-" + name
            return newName
        }
        else return("")
    }

    function handleClick(e) { //ties upload button to ref that uploads the file
        hiddenFileInput.current.click();
    }   

    function handleChange(e) { //uploads csv file
        if (e.target.files[0].name.slice(-3) === "csv") {
            setFile(e.target.files[0])
            setCsvError('')
            setIsCsv(true)
        }
        else {
            setCsvError(true)
        }
    }

    function handleIconClick(e) { //removes csv file 
        setFile()
        setIsCsv(false)
        setRefKey(Date.now)
        setResults()
    }

    useEffect(() => { //checks if form is complete
        if (!isCsv && formData.newNote && formData.name) {
            setIsComplete(true)
        } else if(isCsv && file){
            setIsComplete(true)
        } else setIsComplete(false)
    }, [formData, file, isCsv]);

    function handleRun(e) { // reformats and submits new note from form
        let finalNote = {
            name: formData.name,
            group_name: formData.group,
            note_text: formData.newNote
        }
        props.createNote(finalNote, isCsv)
    }

    function handleCsvRun(e){ //reformats and submits new note from csv
        props.createNote(file, isCsv)
    }

    useEffect(() => { //sets success or error messge after creation
        if (props.results && props.results.message) {
            setResults(props.results)
        }
    }, [props.results]);

    return (
        <div>
            <span className="form-section">
                <h4>Create New Note</h4>
                <textarea className="form-control text-area margin" placeholder="Enter Note text here, or upload CSV below" id="newNote" disabled={isCsv} onChange={handleFormChange} onBlur={handleBlur}></textarea>

                <div className="inline margin"> 
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Name this Note here" id="name" disabled={isCsv} value={formData.name} onChange={handleFormChange}  ></input>
                    </span>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Group Name" value={formData.group} disabled={isCsv} id="group" onChange={handleFormChange} ></input>
                    </span>
                </div>
                <input type="file" key={refKey} ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChange} />
                {file ? <div><FiletypeCsv /> {file.name} <XCircleFill color="#0D6EFD" onClick={handleIconClick} cursor={"pointer"} /></div> :

                    <Button type="file" variant="outline-primary" onClick={handleClick}>Upload CSV</Button>}
                {csvError ? <div className="alert csv-alert alert-danger" role="alert">That is not a CSV file</div> : null}
                {results && results.message ? (results.success ? <Alert className="alert" variant="success">{results.message}</Alert> : <Alert className="alert" variant="danger">{results.message + ": " + results.data}</Alert>) : null}
            </span>
            <div className="run">
                {isComplete ? <Button className="button-run" onClick={isCsv? handleCsvRun : handleRun}>Create</Button> : <Button className="button-run" disabled onClick={handleRun}>Create</Button>}
            </div>
        </div>
    )
}