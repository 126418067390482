import React, { useState } from "react";
import './components.css'
import { Modal, Button } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import  { domToReact } from 'html-react-parser';


export default function CompareViewModal(props) {
    const currentView = props.currentView 
    const [contrastColors, setContrastColors] = useState(false)

   
    const options = {
        replace: ({ attribs, children }) => {
          if (!attribs) return;
          if (attribs.id === 'main') {
            return (
              <h1 style={{ fontSize: 42 }}>{domToReact(children, options)}</h1>
            );
          } else if (attribs.class === 'markup-text') {
            return (
              <span style={{ color: 'goldenrod' }}>
                {domToReact(children, options)}
              </span>
            );
          }
        }
      };

    return (
        // <div className="compare-modal-container">
        <Modal  dialogClassName="compare-modal" show={true} onHide={props.handleCloseView} >
            <Modal.Body>
                <div className="inline-modal margin-bottom">
                    <div className="visual-section">
                        <div className="inline">
                            <span>
                                <b>Note ID:</b> {currentView.noteID} <br />
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={()=>setContrastColors(!contrastColors)}/> Contrast Colors
                                </div>
                            </span>
                            <span className="inline flex-right">
                            </span>
                        </div>
                        <div className="visual-prompt-container">
                            <span className="visual-prompt-label">
                                <b>{currentView.runA.aiVendor} {currentView.runA.aiModel}</b>
                                <span className="visual-prompt-box">{contrastColors? HTMLReactParser(currentView.runA.markupText, options): currentView.note}</span>
                                <b>Same: </b>{currentView.same}
                                <br></br>
                                <b>Unique: </b>{currentView.runA.uniqueKeywords}
                                {!currentView.runA.parseable? <div style={contrastColors?{"color":"goldenrod"} :{}}>Unable to parse JSON</div> : null}
                            </span>
                            <span className="visual-prompt-label">
                                <b>{currentView.runB.aiVendor} {currentView.runB.aiModel}</b>
                                <span className="visual-prompt-box">{contrastColors? HTMLReactParser(currentView.runB.markupText, options): currentView.note}</span>
                                <b>Same: </b>{currentView.same}
                                <br></br>
                                <b>Unique: </b>{currentView.runB.uniqueKeywords}
                                {!currentView.runB.parseable? <div style={contrastColors?{"color":"goldenrod"} :{}}>Unable to parse JSON</div> : null}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-outline-primary" onClick={props.handleCloseView}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        // </div>
    )
}