import React, { useState, useEffect } from "react";
import Spreadsheet from "../../../common/components/Spreadsheet";
import './components.css'
import { Trash, Pencil } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import EditNoteModal from "./EditNoteModal";
import { Eye } from "react-bootstrap-icons/dist";
import ViewNoteModal from "./ViewNoteModal";
import { Navigate} from "react-router-dom";

export default function NoteResults(props) {

    const [showEdit, setShowEdit] = useState(false)
    const [showView, setShowView] = useState(false)
    const [selectCount, setSelectCount] = useState(0)
    const [selected, setSelected] = useState([])
    const [redirectHome, setRedirectHome] = useState(false)

    const fields = [{name: "Note ID", hidden:false},{name: "Content", hidden:false},
    {name: "Name", hidden:false},{name: "Group Name", hidden:false},
    {name: "Date", hidden:false}, {name: "Mod.", hidden:false}, {name: "data", hidden:true}]

    const [data, setData] = useState([])

    const [clear, setClear] = useState()
    const [message, setMessage] = useState("")

    function getSelected(selected, count) {
        setSelected(selected)
        setSelectCount(count)
    }

    useEffect(() => {
        if (props.data && props.data.tableNotes && props.data.tableNotes.length > 0) {
            setData(props.data.tableNotes)
        }
    }, [props])

    useEffect(()=>{
        if(props.deleteResponse && props.deleteResponse.message){
            setMessage("something went wrong: "+ props.deleteResponse.message)
        }
        else if(props.deleteResponse) {
            setMessage("deleted")
            setTimeout(() => {
                setMessage("")
            }, 1000)
        }
    },[props.deleteResponse])

    function deleteNote() {
        setShowEdit(false)
        setMessage("deleting...")
        selected.forEach(element => {
            try {
                props.deleteNote(element[0])
            } catch (error) {
                console.log(error)
            }
        })

        setClear(Date.now)
        setSelected([])
        setSelectCount(0)
    }

    function addToRun(){
        var noteData = selected[0][selected[0].length-1]
        var testFormData = {
            type: '',
            aiVendor: {dropdown: '', value: ''},
            aiModel: {dropdown: '', value: ''},
            note: {dropdown: '', value: ''},
            group: {dropdown: '', value: ''},
            instruction: {dropdown: '', value: ''},
            temp: '',
            tokens: '',
            topP: ''
        }
        var savedTestFormData = sessionStorage.getItem("newTestForm")

        if (savedTestFormData && savedTestFormData !== undefined && savedTestFormData !== "undefined") {
            testFormData = JSON.parse(savedTestFormData)
        }
        testFormData.note = {dropdown: noteData.name , value: JSON.stringify(noteData)}
        sessionStorage.setItem("newTestForm", JSON.stringify(testFormData))

        setRedirectHome(true)
    }

    return (
        <div className="">
            {redirectHome? <Navigate to="/"></Navigate> : null}
            <div>
                <div className="filters-section inline">
                    {selectCount === 0 ?
                        <div className="inline">
                            <Button className="compare-button" disabled>Add to Run</Button>
                            <div className="button-link deactivated"><Eye /> View</div>
                            <div className="button-link deactivated"><Pencil /> Create From</div>
                            <div className="button-link deactivated"><Trash /> Delete</div>
                        </div>
                        : selectCount === 1 ?
                            <div className="inline">
                                <Button className="compare-button" onClick={()=>addToRun()}>Add to Run</Button>
                                <div className="button-link" onClick={() => setShowView(true)}><Eye /> View</div>
                                <div className="button-link" onClick={() => setShowEdit(true)}><Pencil /> Create From</div>
                                <div className="button-link danger" onClick={() => deleteNote()}><Trash /> Delete</div>
                            </div>
                            : <div className="inline">
                                <Button className="compare-button" disabled>Add to Run</Button>
                                <div className="button-link deactivated"><Eye /> View</div>
                                <div className="button-link deactivated"><Pencil /> Create From</div>
                                <div className="button-link danger" onClick={() => deleteNote()}><Trash /> Delete</div>
                            </div>
                    }
                    <div className="save-notif">{message}</div>


                </div>
                <Spreadsheet
                    fields={fields}
                    data={data}
                    isSelectable={true}
                    type="note"
                    getSelected={getSelected}
                    defaultSort={4}
                    clear={clear}
                ></Spreadsheet>

            </div>
            {showEdit ? <EditNoteModal
                handleEditClose={() => setShowEdit(false)}
                selected={selected[0]}
                deleteNote={deleteNote}
                createNote={props.createNote}
            ></EditNoteModal> : null}

            {showView ? <ViewNoteModal
                handleViewClose={() => setShowView(false)}
                selected={selected[0]}
                getFullNote={props.getFullNote}
            ></ViewNoteModal> : null}
        </div>
    )
}