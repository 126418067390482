function transformPrompts(promptsList) {
    var newPromptsList: any[] = []
    let transformedPrompts = {
        promptsList: promptsList,
        tablePrompts : newPromptsList
    }
    if (promptsList && promptsList.length > 0) {
        promptsList.forEach((value, i) => transformedPrompts.tablePrompts.push([value.uuid, value.name, value.prompt_text, value.function, value.created_at, "", value]))
    } 
    return transformedPrompts
}


export const PromptTransformer = {
    transformPrompts
}