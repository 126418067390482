import React from 'react';
import {Navigate} from 'react-router-dom';
import { AuthService } from '../services/AuthService.ts';

function PublicRoute({children}) {
  const authService = AuthService
  const isAuthenticated = authService.getToken()? true : false
  return (
        isAuthenticated ? <Navigate to="/"/>: children
  );
}
export default PublicRoute;