import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import './components.css'
import Pagination from "./Pagination";
import { CaretDownFill, CaretUpFill, Square, CheckSquareFill, Alarm } from "react-bootstrap-icons";

export default function Spreadsheet(props) {

    const [endIndex, setEndIndex] = useState()
    const [startIndex, setStartIndex] = useState()

    const allData = props.data
    const [data, setData] = useState(props.data)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageLength, setPageLength] = useState(10)
    const [tableKey, setTableKey] = useState(Date.now)

    const [currentSort, setCurrentSort] = useState("")
    const [reverse, setReverse] = useState(false)
    const [reverseData, setReverseData] = useState(false)
    const [sortIndex, setSortIndex] = useState(0)

    const [idArray, setIdArray] = useState([])
    const defaultSort = props.defaultSort ? props.defaultSort : 0

    const [useLocalDate, setUseLocalDate] = useState(false)

    function getLocalDate(timestamp){   
        let fullDate = new Date(timestamp + "Z")

        let newDate = fullDate.getDate() + " "  
        newDate += fullDate.toLocaleString('default', {month: 'long'}) + " " + fullDate.getFullYear()

        if(fullDate.getHours() > 12){
            newDate += " " + (fullDate.getHours()-12) + ":" + fullDate.getMinutes() + ":" + fullDate.getSeconds() + " PM"
        }
        else{
            newDate += " " + fullDate.getHours() + ":" + fullDate.getMinutes() + ":" + fullDate.getSeconds() + " AM"
        }

        return newDate   
    }

    useEffect(() => {
        setData(props.data)
        var startIndex = null
        var endIndex  = null
        props.fields.forEach((element, i) => {
            if(!element.hidden){
                endIndex = i
                if(startIndex === null){
                    startIndex = i
                }
            }
        });
        setEndIndex(endIndex)
        setStartIndex(startIndex)
    }, [props])

    useEffect(() => {
        setSortIndex(defaultSort)
    }, [defaultSort])

    useEffect(() => {
        setIdArray([])
    }, [props.clear])

    function setSort(index) {
        if (index !== sortIndex) {
            setSortIndex(index)
            setReverseData(false)
        }
        else if (index === sortIndex && !reverse) {
            setReverse(true)
            setReverseData(true)
        }
        else {
            setReverse(false)
            setReverseData(false)
        }

    }

    function setResults(newIdArray) {
        let count = newIdArray.length
        const selectArray = []
        for (var i = 0; i < data.length; ++i) {
            if (newIdArray.includes(data[i][0])) {
                selectArray.push(data[i])
            }
        }
        props.getSelected(selectArray, count)
    }

    function handleSelect(id) {
        var newIdArray = idArray
        newIdArray.push(id)
        setIdArray(newIdArray)
        setResults(newIdArray)
    }
    function handleDeselect(id) {
        var newIdArray = idArray
        newIdArray.splice(newIdArray.indexOf(id), 1)
        setIdArray(newIdArray)
        setResults(newIdArray)
    }

    const sorted = useMemo(() => {

        let sortedData = [...allData]
        let rows = allData.length
        let shouldSwitch
        let x
        let y
        let switching = true



        if (reverseData) {
            switching = false
            sortedData = [...data]
        }

        while (switching) {
            switching = false
            for (let i = 0; i < rows - 1; i++) {
                shouldSwitch = false
                x = sortedData[i][sortIndex] ? sortedData[i][sortIndex] : "";
                y = sortedData[i + 1][sortIndex] ? sortedData[i + 1][sortIndex] : ""
                if (x && y && x.toString().toLowerCase() < y.toString().toLowerCase()) {
                    shouldSwitch = true;
                }
                if (shouldSwitch) {
                    let temp = sortedData[i]
                    sortedData[i] = sortedData[i + 1]
                    sortedData[i + 1] = temp
                    switching = true;
                }
            }
        }

        if (reverseData) {
            sortedData.reverse()
        }

        setTableKey(Date.now)
        setCurrentSort(sortIndex)

        return sortedData

    }, [sortIndex, allData, data, reverseData])

    const currentPageData = useMemo(() => {
        const firstIndex = (currentPage - 1) * pageLength;
        const lastIndex = firstIndex + Number(pageLength);
        return sorted.slice(firstIndex, lastIndex);
    }, [currentPage, pageLength, sorted])

    return (
        <div>
            <table className="spreadsheet-table" key={tableKey}>
                <thead>
                    <tr>
                        {props.fields.map((field, i) => !field.hidden? (i === startIndex || i === endIndex)?
                            <th className="endCell" key={field.name}>
                                <span onClick={e => setSort(i)}>
                                {field.name}
                                    {i === currentSort ? reverse ? <CaretUpFill /> 
                                        : <CaretDownFill /> : null}
                                </span>
                                {field.name==="Date"?
                                    <Alarm 
                                        className={useLocalDate? "time-icon active" : "time-icon"} 
                                        data-toggle="tooltip" 
                                        title={useLocalDate? "Switch to GMT" : "Switch to local timezone"} 
                                        onClick={()=>setUseLocalDate(!useLocalDate)}/> 
                                : null}
                            
                            </th>
                            : 
                            <th key={field.name}>
                                <span onClick={e => setSort(i)}>
                                    {field.name}
                                    {i === currentSort ? reverse ? <CaretUpFill />
                                        : <CaretDownFill /> : null}
                                </span>
                                {field.name==="Date"?
                                    <Alarm 
                                    className={useLocalDate? "time-icon active" : "time-icon"} 
                                    data-toggle="tooltip" 
                                        title={useLocalDate? "Switch to GMT" : "Switch to local timezone"} 
                                        onClick={()=>setUseLocalDate(!useLocalDate)}/> 
                                : null}
                            </th>
                            :null
                        )}
                    </tr>
                </thead>
                <tbody>
                    {currentPageData.map((currData, i) =>
                        <tr className="endCell" key={i} style={{"--colCount":props.fields.length}}>
                            {props.isSelectable ?
                                <td className="endCell">
                                    {idArray.includes(currData[0]) ?
                                        <CheckSquareFill onClick={() => handleDeselect(currData[0])} /> :
                                        <Square onClick={() => handleSelect(currData[0])} />}
                                    {" " + currData[startIndex]}
                                </td>
                                :
                                <td className="endCell">
                                    {currData[0]}
                                </td>}
                            {currData.map((field, i) =>
                                i === startIndex || props.fields[i].hidden ? null :
                                    i === endIndex ? <td className="endCell" key={i}>{props.fields[i].name==="Date" && useLocalDate? getLocalDate(field) : field}</td> :
                                     <td key={i}>{props.fields[i].name==="Date" && useLocalDate? getLocalDate(field) : field}</td>)}
                        </tr>
                    )}
                </tbody>
            </table>
            <Pagination
                totalCount={data.length}
                pageLength={pageLength}
                onPageLengthChange={value => setPageLength(value)}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
            ></Pagination>
        </div>
    )
}