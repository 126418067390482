import React, { useState, useEffect } from "react";
import Spreadsheet from "../../../common/components/Spreadsheet";
import './components.css'

export default function LlmResults(props) {

    const fields = [{name: "ID", hidden:false},{name: "Vendor Name", hidden:false} ,{name: "Engine Name", hidden:false},
    {name: "Created", hidden:false}, {name: "data", hidden:true}]
    const [data, setData] = useState([])

    useEffect(()=>{
        if(props.data && props.data.tableLlms && props.data.tableLlms.length > 0){
            setData(props.data.tableLlms)
        }
    },[props])

    return (
        <div className="">
            <div>
                <Spreadsheet
                    fields={fields}
                    data={data}
                    isSelectable={false}
                    type="llm"
                    defaultSort={3}
                ></Spreadsheet>
            </div>
        </div>
    )
}