import React from "react";
import { usePagination, DOTS } from "../hooks/usePagination";
import { DropdownButton, Dropdown, Pagination as BootPagination } from "react-bootstrap";
import './components.css'

export default function Pagination(props) {
  const {
    onPageChange,
    onPageLengthChange,
    totalCount,
    currentPage,
    pageLength,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageLength,
  });

  function onFirst(){
    onPageChange(1)
  }

  function onNext(){
    onPageChange(currentPage + 1);
  };

  function onPrevious(){
    onPageChange(currentPage - 1);
  }

  function handleSetPage(page){
    if (page > lastPage) {
      onPageChange(Number(lastPage))
    }
    else if (page < 1) {
      onPageChange(1)
    }
    else {
      onPageChange(Number(page));
    }
  }

  function onLast(){
    onPageChange(lastPage)
  }

  function onSelectPageLength(e){
    onPageChange(1)
    onPageLengthChange(e)
  }

  let lastPage = paginationRange[paginationRange.length - 1];

  if ((currentPage === 0 || paginationRange.length < 2) && totalCount < 10) {
    return null;
  }
  else if ((currentPage === 0 || paginationRange.length < 2) && totalCount > 10) {
    return (
      <div className="inline page-nav">
        <DropdownButton className="page" onSelect={onSelectPageLength} title={<div className="dropdown-title">{pageLength}/page</div>} id="page">
          <Dropdown.Item eventKey={10} value={10}>10</Dropdown.Item>
          <Dropdown.Item eventKey={15} value={15}>15</Dropdown.Item>
          <Dropdown.Item eventKey={20} value={20}>20</Dropdown.Item>
        </DropdownButton>
      </div>
    )
  }

  return (
    <div className="inline page-nav">
      <div className="page-text">Total {totalCount} items</div>
      <BootPagination>
        <BootPagination.First disabled={currentPage === 1} onClick={onFirst} />
        <BootPagination.Prev onClick={onPrevious} disabled={currentPage === 1} />

        {paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return <BootPagination.Ellipsis key={i} />
          }

          if (pageNumber === currentPage) {
            return <BootPagination.Item key={i} active>{pageNumber}</BootPagination.Item>
          }

          return (
            <BootPagination.Item key={i} onClick={e => handleSetPage(pageNumber)}>{pageNumber}</BootPagination.Item>
          )

        })}
        <BootPagination.Next onClick={onNext} disabled={currentPage === lastPage} />
        <BootPagination.Last disabled={currentPage === lastPage} onClick={onLast} />

      </BootPagination>

      <DropdownButton className="page" onSelect={onSelectPageLength} title={<div className="dropdown-title">{pageLength}/page</div>} id="page">
        <Dropdown.Item eventKey={10} value={10}>10</Dropdown.Item>
        <Dropdown.Item eventKey={15} value={15}>15</Dropdown.Item>
        <Dropdown.Item eventKey={20} value={20}>20</Dropdown.Item>
      </DropdownButton>
      <div className="page-text">Go To:</div>
      <input className="form-control page-input" type="number" onBlur={e => e.target.value ? handleSetPage(e.target.value) : null}></input>
    </div>
  )

}