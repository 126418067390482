import axios from "axios"
import { AuthService } from "../../../common/services/AuthService.ts"
import { LlmTransformer } from "../transformers/LlmTransformer.ts";

const authservice = AuthService
const transformer = LlmTransformer


async function getLLMs() {
    var token = authservice.getToken()
    var llmsList
    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/llms/',
        headers: {
            'Authorization': token
        }
    };

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    llmsList = transformer.transformLlms(results)
    return llmsList
}
async function createLLM(newLLM) {

    var functionList: any[] = []

    newLLM.function_type.forEach(element => {
        var type

        switch (element) {
            case "Keyword":
                type = "keywords"
                break;
            case "Topic":
                type = "topics"
                break;
            case "Sentiment":
                type = "sentiment"
                break;
            case "Emotion":
                type = "emotions"
                break;
            default:
                type = ""
                break;
        }
        functionList.push(type)
    });

    var data= JSON.stringify({
            "vendor_name": newLLM.vendor_name,
            "engine_name": newLLM.engine_name,
            "function_type": functionList,
            "cost": newLLM.cost,
            "cost_base_tokens": newLLM.cost_base_tokens,
            "prompt_format_short": newLLM.prompt_format_short,
            "prompt_format_long": newLLM.prompt_format_long
      })
    var token = authservice.getToken()
    var results = {success: false, message: '', data: ''}

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/llms/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: data
    };
  
        await axios(config)
            .then(function (response) {
                results.success = true
                results.message = "LLM successfully added"
                results.data = response.data
            })
            .catch(function (error) {
            results.success = false
            results.message = error.message
            results.data = error.response.statusText
            console.log(error)
            return error
    });
    return results   
}

export const LlmService = {
    getLLMs,
    createLLM,
}