import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './signin/views/Login';
import Home from './homepage/views/Home';
import Compare from './compare/views/Compare';
import DataRuns from './dataruns/views/DataRuns';
import PrivateRoute from './common/utils/PrivateRoute';
import PublicRoute from './common/utils/PublicRoute.js';
import Notes from './database/notes/views/Notes.js';
import Prompts from './database/prompts/views/Prompts.js';
import Llms from './database/llms/views/LLms.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }>
        </Route>
        <Route path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
        <Route path="/compare"
          element={
            <PrivateRoute>
              <Compare />
            </PrivateRoute>
          } />
        <Route path="/dataruns"
          element={
            <PrivateRoute>
              <DataRuns />
            </PrivateRoute>
          } />
          <Route path="/database/notes"
          element={
            <PrivateRoute>
              <Notes />
            </PrivateRoute>
          } />
          <Route path="/database/prompts"
          element={
            <PrivateRoute>
              <Prompts />
            </PrivateRoute>
          } />
          <Route path="/database/llms"
          element={
            <PrivateRoute>
              <Llms />
            </PrivateRoute>
          } />
          
      </Routes>
    </BrowserRouter>
  );
}

export default App;
