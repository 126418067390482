function transformPrompts(promptsList) {
    var newPromptsList: any[] = []
    let transformedPrompts = {
        promptsList: promptsList,
        tablePrompts : newPromptsList
    }
    if (promptsList && promptsList.length > 0) {
        promptsList.forEach((value, i) => transformedPrompts.tablePrompts.push([value.uuid, value.name, value.prompt_text, value.function, value.created_at, ""]))
    } 
    return transformedPrompts
}

function transformLlms(llmsList) {
    var newLlmsList: any[] = []
    let transformedLlms = {
        llmsList: llmsList,
        tableLlms : newLlmsList
    }
    if (llmsList && llmsList.length > 0) {
        llmsList.forEach((value, i) => transformedLlms.tableLlms.push([value.uuid, value.vendor_name, value.engine_name, value.created_at]))
    } else return []
    return transformedLlms
}

function transformNotes(notesList) {
    var newNotesList: any[] = []
    let transformedNotes = {
        notesList: notesList,
        tableNotes : newNotesList
    }
    if (notesList && notesList.length > 0) {
        notesList.forEach((value, i) => transformedNotes.tableNotes.push([value.uuid, value.note_text, value.name, value.group_name, value.created_at, ""]))
    } else return []
    return transformedNotes
}

function transformDropdownData(notesList, promptsList, llmsList){
    var newNotesList: any[] = []
    var newPromptsList: any[] = []
    var newGroupList: any[] = []
    var groupListRef: any[] = []

    var newVendorList: any[] = []
    var newEngineList: any[] = []
    var newLlmsList

    if (notesList && notesList.notesList && notesList.notesList.length > 0) {
        notesList.notesList.forEach((value, i) => {
            newNotesList.push({dropdown: value.name, value: JSON.stringify(value) })
            if (value.group_name !== '' && (groupListRef.length === 0 || !groupListRef.includes(value.group_name))) {
                newGroupList.push({dropdown: value.group_name}) 
                groupListRef.push(value.group_name)
            }
        })
    }
    if (promptsList && promptsList.promptsList && promptsList.promptsList.length > 0) {
        promptsList.promptsList.forEach((value, i) => newPromptsList.push({dropdown: value.prompt_text, value: JSON.stringify(value)}))
    } 

    if (llmsList && llmsList.llmsList && llmsList.llmsList.length > 0) {
        llmsList.llmsList.forEach((value, i) => {
            if (newVendorList.length === 0 || !newVendorList.includes(value.vendor_name)) {
                newVendorList.push({dropdown: value.vendor_name})
            }
            newEngineList.push({dropdown:{ vendor: value.vendor_name, engine_name: value.engine_name }, value: JSON.stringify(value)})
        })
    }
    newLlmsList = {
        vendorList: newVendorList,
        engineList: newEngineList
    }

    let data = {
            llmsList: newLlmsList,
            promptsList: newPromptsList,
            notesList: newNotesList,
            groupsList: newGroupList
    }
    return data

}

export const LlmTransformer = {
    transformPrompts,
    transformLlms,
    transformNotes,
    transformDropdownData
}