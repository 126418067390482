import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
import './components.css'


export default function CompareSummary(props) {

    const title = "Compare Results"

    const type = props.data.type
    const notes = 2 //TODO: change for groups

    const date = props.date ? props.date.getFullYear() + "-" + props.date.getMonth() + "-" + props.date.getDate() + " "
        + props.date.getHours() + ":" + props.date.getMinutes() + ":" + props.date.getSeconds()
        : null;

    const [runA, setRunA] = useState()
    const [runB, setRunB] = useState()
    const [note, setNote] = useState()

    useEffect(() => {
        if (props && props.data && props.data[0] && props.data[0].runA && !runA && !runB) {
            setRunA(props.data[0].runA)
            setRunB(props.data[0].runB)
            console.log(props.data[0].runA)
            setNote(props.data[0].note)
        }
    }, [setRunA, setRunB, props, runA, runB])

    return (
        <div className="summaryContainer">
            <h2>{title}</h2>
            <div className="summary-section">
                <span className="summary-right">
                    <div className="summary-right-top">
                        <div>
                            Type: <b>{type}</b>
                        </div>
                        <div>
                            Notes: <b>{notes}</b>
                        </div>
                    </div>
                    <br></br>
                    <div className="summary-right-bottom">
                        <div>
                            {/* <b>Source: </b>{props.data.group.dropdown ? props.data.group.dropdown : props.data.note.dropdown} */}
                        </div>
                        <div>
                            <b>Date: </b>{date}
                        </div>
                        <Button variant="btn btn-outline-primary" className="margin">Download <Download /></Button>
                        <div>
                            <b>Note: </b><br></br>
                            {note}
                        </div>
                    </div>
                </span>

                {runA ?
                    <div className="summary-left ms-auto">

                        <div className="summary-prompt-box">
                            <table className="compare-summary-table">
                                <tbody>
                                    <tr>
                                        <td className="top">
                                            <span><b>Run A Run ID: </b>{runA.resultID}</span> <br></br>
                                            <span><b>Run A Result ID: </b>{runA.resultID}</span> <br></br>
                                            <span><b>Prompt: </b>{runA.prompt}</span>
                                        </td>
                                        <td className="top">
                                            <span><b>Run B Run ID: </b>{runB.resultID}</span> <br></br>
                                            <span><b>Run B Result ID: </b>{runB.resultID}</span> <br></br>
                                            <span><b>Prompt: </b>{runB.prompt}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Vendor: </b>{runA.aiVendor}
                                            <br />
                                            <b>Engine: </b>{runA.aiModel}
                                        </td>

                                        <td>
                                            <b>Vendor: </b>{runB.aiVendor}
                                            <br />
                                            <b>Engine: </b>{runB.aiModel}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bottom">
                                            <b>Temperature: </b>{runA.temp}<br />
                                            <b>Max Tokens: </b>{runA.tokens ? runA.tokens : "---"}<br />
                                            <b>Top P: </b>{runA.topP ? runA.topP : "---"}
                                        </td>

                                        <td className="bottom">
                                            <b>Temperature: </b>{runB.temp}<br />
                                            <b>Max Tokens: </b>{runB.tokens ? runB.tokens : "---"}<br />
                                            <b>Top P: </b>{runB.topP ? runB.topP : "---"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : null}

            </div>
        </div>
    )
}