import axios from "axios"
import { AuthService } from "../../../common/services/AuthService.ts"
import { NotesTransformer } from "../transformers/NotesTransformer.ts";
// import { createReadStream } from "fs"
// const fs = require('fs')

const authservice = AuthService
const transformer = NotesTransformer


async function getNotes() {
    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/notes/',
        headers: {
            'Authorization': token,
        }
    };

    var notesList

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    notesList = transformer.transformNotes(results)
    return notesList
}
async function createNote(newNote) {
    var token = authservice.getToken()
    var data = JSON.stringify({ "name": newNote.name, "group_name": newNote.group_name, "note_text": newNote.note_text });
    var results = { success: false, message: '', data: '' }

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/notes/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: data
    };
    await axios(config)
        .then(function (response) {
            results.success = true
            results.message = "Note added successfully"
            results.data = response.data
            return response.data
        })
        .catch(function (error) {
            results.success = false
            results.message = error.message
            results.data = error.response.statusText
            console.log(error)
            return error
        });
    return results
}

async function uploadCSV(file) {

    var token = authservice.getToken()
    // var data = JSON.stringify({ "name": newNote.name, "group_name": newNote.group_name, "note_text": newNote.note_text });
    var results = { success: false, message: '', data: '' }

    let data = new FormData();

    data.append('file', file);

    var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/upload/',
        headers: {
            'Authorization': token,
        },
        data: data
    };
    await axios(config)
        .then(function (response) {
            results.success = true
            results.message = "Notes added successfully"
            results.data = response.data
            return response.data
        })
        .catch(function (error) {
            results.success = false
            results.message = error.message
            results.data = error.response.statusText
            console.log(error)
            return error
        });
    console.log(results)
    return results
}

async function createNotesCsv(newNotesArr) {
    var results = { success: true, message: '', data: '' }

    newNotesArr.forEach(note => {
        createNote(note).then(results => {
            if (!results.success) {
                results.success = false
                results.message = "at least one note creation failed with message: " + results.message
            }
            else {
                results.success = true
                results.message = "All notes added successfully"
            }
        })
    })
    return results
}
async function getNote(noteID) {

    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/notes/' + noteID,
        headers: {
            'Authorization': token,
        }
    };

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });

    return results
}

async function deleteNote(uuid) {

    var token = authservice.getToken()

    var config = {
        method: 'delete',
        url: 'https://dev.toself.io/v1/notes/' + uuid,
        headers: {
            'Authorization': token,
        }
    };

    let results

    await axios(config)
        .then((response) => {
            results = response
        })
        .catch(function (error) {
            console.log(error);
            results = error
        });
        
    return results
}
export const NotesService = {
    getNotes,
    createNote,
    createNotesCsv,
    getNote,
    deleteNote,
    uploadCSV
}