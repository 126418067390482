import React from 'react';
import {Navigate} from 'react-router-dom';
import { AuthService } from '../services/AuthService.ts';

function PrivateRoute({children}) {
  const authService = AuthService
  const isAuthenticated = authService.getToken()? true : false
  return (
        isAuthenticated ? children : <Navigate to="/login"/>
  );
}
export default PrivateRoute;