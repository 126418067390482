import React, { useState, useEffect } from "react"
import Copyright from "../../../common/components/Copyright"
import './views.css'
import NavBar from "../../../common/components/NavBar"
import { LlmService } from "../services/LlmService.ts"
import NewLlm from "../components/NewLlm.js"
import LlmResults from "../components/LlmResults.js"


export default function Llms() {

    const llmService = LlmService
    const [llmsList, setLlmsList] = useState()
    const [results, setResults] = useState()
    const [resultsTableKey, setResultsTableKey] = useState()

    useEffect(() => {
        llmService.getLLMs().then(value => setLlmsList(value))
        setResultsTableKey(Date.now())
    }, [llmService, results])

    function createLlm(data) {
        llmService.createLLM(data).then(value => setResults(value))
    }
    return (
        <div className="app-container">
            <div className="pageContainer">
                <NavBar
                    showDatabaseBar={true}
                />
                <div className="component-container">
                    <div className="form-box-left">
                        <NewLlm
                            createLlm={createLlm}
                            results={results}
                        />
                    </div>

                    <LlmResults
                        key={resultsTableKey}
                        data={llmsList}
                    ></LlmResults>
                </div>
            </div>
            <Copyright />
        </div>
    )
}
