import React, { useEffect, useState } from "react"
import NavBar from "../../common/components/NavBar"
import NewTest from "../components/NewTest"
import Copyright from "../../common/components/Copyright"
import TestResults from "../components/TestResults"
import './views.css'
import { TestService } from "../services/TestService.ts"
import { DatabaseService } from "../services/DatabaseService.ts"
import { Spinner } from "react-bootstrap"

export default function Home() {
    const testService = TestService
    const databaseService = DatabaseService

    const [dropdownData, setDropdownData] = useState()

    useEffect(() => {
        databaseService.getDropdownData().then(value => {
            setDropdownData(value)
        })
    }, [databaseService])


    const blankForm = {
        type: '',
        aiVendor: { dropdown: '', value: '' },
        aiModel: { dropdown: '', value: '' },
        note: { dropdown: '', value: '' },
        group: { dropdown: '', value: '' },
        instruction: { dropdown: '', value: '' },
        temp: '',
        tokens: '',
        topP: ''
    }

    const [currentFormData, setCurrentFormData] = useState(blankForm)
    const [finalFormData, setFinalFormData] = useState(blankForm)
    const [testResults, setTestResults] = useState()
    const [editError, setEditError] = useState("")
    const [testKey, setTestKey] = useState(Date.now())
    const [resultsKey, setResultsKey] = useState(Date.now())
    const [loading, setLoading] = useState(false)
    const [groupNotesList, setGroupNotesList] = useState([])

    useEffect(() => {
        var savedTestFormData = sessionStorage.getItem("newTestForm")
        if (savedTestFormData && savedTestFormData !== undefined && savedTestFormData !== "undefined") {
            setCurrentFormData(JSON.parse(savedTestFormData))
            setTestKey(Date.now())
        }

        var savedFinalFormData = sessionStorage.getItem("finalFormData")
        if (savedFinalFormData && savedFinalFormData !== undefined && savedFinalFormData !== "undefined") {
            setFinalFormData(JSON.parse(savedFinalFormData))
        }

        var savedResultsId = sessionStorage.getItem("resultID")
        if (savedResultsId && savedResultsId !== undefined && savedResultsId !== "undefined") {
            testService.getRun(savedResultsId).then(response => {
                if (!response.message) {
                    setTestResults([response])
                }
            })
        }
    }, [testService])

    useEffect(() => {
        if (testResults && testResults[0] && testResults[0].uuid) {
            sessionStorage.setItem("resultID", testResults[0].uuid)
        }
        setLoading(false)
    }, [testResults])

    function setTestForm(value) {
        sessionStorage.setItem("newTestForm", JSON.stringify(value))
        setCurrentFormData(value)
    }

    async function submitNotesCsv(csvFile) {
        let uploadResults
        uploadResults = await testService.uploadCSV(csvFile)
        return uploadResults
    }

    function onRun(value, isCsv) {
        if (!value.note.dropdown && !isCsv) {
            return
        }
        setLoading(true)
        setFinalFormData(value)
        sessionStorage.setItem("finalFormData", JSON.stringify(value))
        testService.runTest(value, isCsv).then(e => {
            setTestResults(e)
            setResultsKey(Date.now())
        })
    }

    function handleClear() {
        sessionStorage.removeItem("newTestForm")
        setCurrentFormData(blankForm)
        setTestKey(Date.now())
    }
    function handleSaveEdit(data, id) {
        let result
        let newData = [...testResults]

        testService.saveEdit(data, id).then(value => {
            result = value
            if (!result.message) {
                newData[0].run_name = result.run_name ? result.run_name : newData[0].run_name
                newData[0].grade = result.grade ? result.grade : newData[0].grade
                newData[0].rank = result.rank ? result.rank : newData[0].rank
                setTestResults(newData)
                setEditError("")
            }
            else {
                setEditError(result)
            }
        })
    }

    function deleteTest() {
        let id = testResults[0].uuid
        testService.deleteRun(id).then(response => {
            if (!response.message) {
                setTestResults()
                sessionStorage.removeItem("resultID")
            }
        })
    }

    function getNotesByGroup(groupName){
        databaseService.getNotesByGroup(groupName).then(value=>{
            setGroupNotesList(value)
        })
    }

    return (
        <span>
            <div className="pageContainer">
                <NavBar />
                <div className="component-container">
                    <NewTest
                        key={testKey}
                        onRun={onRun}
                        handleClear={handleClear}
                        setTestForm={setTestForm}
                        currentFormData={currentFormData}
                        dropdownData={dropdownData}
                        submitNotesCsv={submitNotesCsv}
                        getNotesByGroup={getNotesByGroup}
                        groupNotesList={groupNotesList}
                    ></NewTest>
                    {loading ?
                        <div className="spinner-container">
                            <Spinner animation="border" variant="primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div> : null}
                    {testResults ?
                        <TestResults
                            key={resultsKey}
                            formData={finalFormData}
                            resultData={testResults}
                            handleSaveEdit={handleSaveEdit}
                            editError={editError}
                            deleteTest={deleteTest}
                        ></TestResults>
                        : null}
                </div>
            </div>
            <Copyright />
        </span>
    )
}