import axios from "axios"
import { AuthService } from "../../common/services/AuthService.ts";

const authservice = AuthService

async function runTest(props, isCsv) {
    var token = authservice.getToken()

    const fullDate = new Date()
    const date = fullDate.getFullYear().toString() + (fullDate.getMonth() + 1).toString().padStart(2, "0") + fullDate.getDate().toString().padStart(2, "0")

    let results
    let autoName
    let name = props.instruction.dropdown !== '' ? props.instruction.dropdown : "Not Found"
    let params= "_temp" + props.temp
    if (props.instruction.dropdown !== '') {
        let newName = name.split(' ').slice(0, 5).join("-")
        name = newName.replace(/[.,#!$%^&*;:{}=_`~() ]/g, "")
        name = "_" + name 
    }
    
    params = props.topP? params + "_top-p"+ props.topP : params
    params = props.tokens? params + "_tokens" + props.tokens : params

    autoName = date + name + params
    let noteData

    if(!isCsv && !props.group.value ){
        noteData = [JSON.parse(props.note.value)]
    }
    else if(props.group.value && !isCsv){
        noteData=JSON.parse(props.note.value)
    }
    else{
        noteData = props.note
    }    
    
    let data = {
        note: noteData,
        prompt: JSON.parse(props.instruction.value),
        llm: JSON.parse(props.aiModel.value),
        run_name: autoName,
        temperature: parseFloat(props.temp),
        top_p: parseFloat(props.topP)? parseFloat(props.topP): null,
        max_tokens: parseInt(props.tokens) ? parseInt(props.tokens) : null
    }

    var resultArray: any[] = []

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)    
    };

    console.log(config)

    await axios.request(config)
        .then((response) => {
            results = response.data
        })
        .catch((error) => {
            results = error
            console.log(error);
        });

    resultArray.push(results)
    return resultArray
}

async function uploadCSV(file) {
    var token = authservice.getToken()
    var results = { success: false, message: '', data: '' }

    let data = new FormData();

    data.append('file', file);

    var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/upload/',
        headers: {
            'Authorization': token,
        },
        data: data
    };
    await axios(config)
        .then(function (response) {
            results.success = true
            results.message = "Notes added successfully"
            results.data = response.data
            return response.data
        })
        .catch(function (error) {
            results.success = false
            results.message = error.message
            results.data = error.response.statusText
            console.log(error)
            return error
        });

    console.log(results)
    return results
}

async function saveEdit(data, id){
    var token = authservice.getToken()
    var results

    const fullDate = new Date()

    var newData = {...data, updated_at: fullDate}
    let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + id,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(newData)    
    };

    await axios.request(config)
        .then((response) => {
            results = response.data
        })
        .catch((error) => {
            results = error
        });

    return results
}

async function getRun(uuid){
    var token = authservice.getToken()

    let configB = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + uuid,
        headers: {
            'Authorization': token
        }
    };

    let results = await axios.request(configB)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error);
            return error
        });

        return results

}
async function deleteRun(uuid){
    var token = authservice.getToken()

    let configB = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: 'https://dev.toself.io/v1/notes/results/' + uuid,
        headers: {
            'Authorization': token
        }
    };

    const results = await axios.request(configB)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error);
            return error
        });

    return results
}

export const TestService = {
    runTest,
    saveEdit,
    getRun,
    deleteRun,
    uploadCSV
}