import axios from "axios"
import { AuthService } from "../../common/services/AuthService.ts";
import { DatabaseTransformer } from "../transformers/DatabaseTransformer.ts";

const authservice = AuthService
const dbTransformer = DatabaseTransformer

async function getPrompts() {
    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/prompts/', //replace dev url with enviroment variable
        headers: {
            'Authorization': token
        }
    };

    var promptsList

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    promptsList = dbTransformer.transformPrompts(results)
    return promptsList
}
async function getPromptById(id) {
    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/prompts/' + id,
        headers: {
            'Authorization': token
        }
    };

    var prompt

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    prompt = results
    return prompt
}
async function createPrompt(newPrompt) {

    var type

    switch (newPrompt.function) {
        case "Keyword":
            type = "keywords"
            break;
        case "Topic":
            type = "topics"
            break;
        case "Sentiment":
            type = "sentiment"
            break;
        case "Emotion":
            type = "emotions"
            break;
        default:
            type = ""
            break;
    }

    var data = JSON.stringify({"name":newPrompt.name ,"function": type ,"prompt_text":newPrompt.prompt_text});

    var token = authservice.getToken()

    var results = {success: false, message: '', data: ''}

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/prompts/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: data
    };
        await axios(config)
            .then(function (response) {
                results.success = true
                results.message = "Prompt added successfully"
                results.data = response.data
                return response.data
            })
            .catch(function (error) {
                results.success = false
                results.message = error.message
                results.data = error.response.statusText
                console.log(error)
                return error
    });
    return results
}

async function getNotes() {
    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/notes/',
        headers: {
            'Authorization': token,
        }
    };

    var notesList

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    notesList = dbTransformer.transformNotes(results)
    return notesList
}
async function createNote(newNote) {
    var token = authservice.getToken()
    var data = JSON.stringify({ "name": newNote.name, "group_name": newNote.group_name, "note_text": newNote.note_text });
    var results = {success: false, message: '', data: ''}

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/notes/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: data
    };
    await axios(config)
        .then(function (response) {
            results.success = true
            results.message = "Note added successfully"
            results.data = response.data
            return response.data
        })
        .catch(function (error) {
            results.success = false
            results.message = error.message
            results.data = error.response.statusText
            console.log(error)
            return error
        });   
    return results
}
async function createNotesCsv(newNotesArr){
    var results = {success: true, message: '', data: ''}

    newNotesArr.forEach(note =>{
        createNote(note).then(results =>{
            if (!results.success){
                results.success = false
                results.message = "at least one note creation failed with message: " + results.message
            }
            else{
                results.success = true
                results.message = "All notes added successfully"
            }
        })
    })
    return results
}

async function getLLMs() {
    var token = authservice.getToken()
    var llmsList
    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/llms/',
        headers: {
            'Authorization': token
        }
    };

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    llmsList = DatabaseTransformer.transformLlms(results)
    return llmsList
}
async function createLLM(newLLM) {

    var functionList: any[] = []

    newLLM.function_type.forEach(element => {
        var type

        switch (element) {
            case "Keyword":
                type = "keywords"
                break;
            case "Topic":
                type = "topics"
                break;
            case "Sentiment":
                type = "sentiment"
                break;
            case "Emotion":
                type = "emotions"
                break;
            default:
                type = ""
                break;
        }
        functionList.push(type)
    });

    var data= JSON.stringify({
            "vendor_name": newLLM.vendor_name,
            "engine_name": newLLM.engine_name,
            "function_type": functionList,
            "cost": newLLM.cost,
            "cost_base_tokens": newLLM.cost_base_tokens,
            "prompt_format_short": newLLM.prompt_format_short,
            "prompt_format_long": newLLM.prompt_format_long
      })
    var token = authservice.getToken()
    var results = {success: false, message: '', data: ''}

    var config = {
        method: 'post',
        url: 'https://dev.toself.io/v1/llms/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: data
    };
  
        await axios(config)
            .then(function (response) {
                results.success = true
                results.message = "LLM successfully added"
                results.data = response.data
            })
            .catch(function (error) {
            results.success = false
            results.message = error.message
            results.data = error.response.statusText
            console.log(error)
            return error
    });
    return results   
}

async function getDropdownData() {
    let prompts = await getPrompts()
    let notes = await getNotes()
    let llms = await getLLMs()

    let dropdownData = DatabaseTransformer.transformDropdownData(notes, prompts, llms)
    
    return dropdownData

}

async function getNote(noteID){

    var token = authservice.getToken()

    var config = {
        method: 'get',
        url: 'https://dev.toself.io/v1/notes/'+ noteID,
        headers: {
            'Authorization': token,
        }
    };

    const results =
        await axios(config)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
                return error
            });

    return results
}
export const DatabaseService = {
    getPrompts,
    getPromptById,
    createPrompt,
    getLLMs,
    createLLM,
    getNotes,
    createNote,
    createNotesCsv,
    getDropdownData,
    getNote
}