import React from "react"
import NavBar from "../../common/components/NavBar"
import Copyright from "../../common/components/Copyright"
import NewCompare from "../components/NewCompare"
import CompareResults from "../components/CompareResults"
import { useState, useEffect } from "react"
import './views.css'
import { DatabaseService } from "../services/DatabaseService.ts"
import { DataRunsService } from "../services/DataRunsService.ts"


export default function Compare() {
    const databaseService = DatabaseService
    const dataRunsService = DataRunsService

    const [compareData, setCompareData] = useState()
    const [compareKey, setCompareKey] = useState(Date.now())
    const [spreadsheetData, setSpreadsheetData] = useState([])
    const [dropdownData, setDropdownData] = useState()
    const [runsDropdown, setRunsDropdown] = useState()
    const [resultsKey, setResultsKey] = useState()

    useEffect(()=> {
       databaseService.getDropdownData().then(value=>setDropdownData(value))
       dataRunsService.getRunsDropdown().then(value=>setRunsDropdown(value))
    }, [databaseService, dataRunsService])

    function onCompare(value) {
        setCompareData(value)
        let runAId = JSON.parse(value.runA.value).resultID
        let runBId = JSON.parse(value.runB.value).resultID
        dataRunsService.getCompareRuns(runAId, runBId).then(value=> setSpreadsheetData(value))
        setResultsKey(Date.now())
    }
    function handleClear(){
        setCompareKey(Date.now())
    }
    return (
        <div className="app-container">
            <div className="pageContainer">
                <NavBar />
                <div className="component-container">
                    <NewCompare
                        key={compareKey}
                        onCompare={onCompare}
                        handleClear={handleClear}
                        dropdownData={dropdownData}
                        runsDropdown = {runsDropdown}
                    />
                    {compareData ?
                        <CompareResults
                            data={compareData}
                            spreadsheetData = {spreadsheetData}
                            date={new Date()}
                            key={resultsKey}
                        />
                        : null
                    }
                </div>
            </div>
            <Copyright />
        </div>
    )
}
