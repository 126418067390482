import React, { useEffect, useState } from "react"
import NavBar from "../../common/components/NavBar"
import Copyright from "../../common/components/Copyright"
import DataRunsResults from "../components/DataRunsResults"
import { DataRunsService } from "../services/DataRunsService.ts";
import './views.css'

export default function DataRuns() {
    const dataRunsService = DataRunsService

    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [csvData, setCsvData] = useState([])

    const [deleteResponse, setDeleteResponse] = useState()

    function getData() {
        dataRunsService.getDataRunsList().then(value => setData(value))
    }

    useEffect(() => {
        var runsArray = []
        if (data && data.length > 0) {
            data.forEach(element => {
                runsArray.push([element.uuid, element.run_name, element.prompt.function, element.note.group_name,
                element.grade, element.prompt.prompt_text,
                element.llm.vendor_name + " " + element.llm.engine_name,
                    "1", element.created_at, element])
            });
        }
        setTableData(runsArray)

        var csvArray = []
        if (data && data.length > 0) {
            data.forEach(element => {
                csvArray.push([element.run_name, element.uuid, element.prompt.function,
                element.parameters.temperature, element.parameters.top_p, element.parameters.max_tokens,
                element.llm.vendor_name + element.llm.engine_name,
                element.prompt.uuid, element.prompt.name, element.prompt.prompt_text,
                element.note.uuid, element.note.name, element.note.note_text,
                element.keywords.results, element.grade, element.rank
                ])
            })
        }
        setCsvData(csvArray)
    }, [data])

    useEffect(() => {
        if (tableData.length === 0) {
            getData()
        }
    })

    function deleteRun(uuid) {
        dataRunsService.deleteRun(uuid).then(response => {
            setDeleteResponse(response)
            getData()
        })
    }

    return (
        <span>
            <div className="pageContainer">
                <NavBar />
                <div className="component-container">
                    {tableData ?
                        <DataRunsResults
                            tableData={tableData}
                            deleteRun={deleteRun}
                            deleteResponse={deleteResponse}
                            csvData={csvData}
                        />
                        : null}
                </div>
            </div>
            <Copyright />
        </span>
    )
}
