import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import './components.css'

export default function NewLlm(props) {
    const [formData, setFormData] = useState({
        vendor_name: "",
        engine_name: "",
        function_type: [],
        cost: "",
        cost_base_tokens: "",
        prompt_format_short: "",
        prompt_format_long: ""
    })
    const [isComplete, setIsComplete] = useState(false)
    const [results, setResults] = useState()

    useEffect(() => {
        if (props.results && props.results.message) {
            setResults(props.results)
        } 
    }, [props.results]);

    useEffect(() => {
        if (formData.vendor_name && formData.engine_name && formData.function_type.length > 0 && formData.cost && formData.cost_base_tokens && formData.prompt_format_long && formData.prompt_format_short) {
            setIsComplete(true)
        } else setIsComplete(false)
    }, [formData]);

    function handleFormChange({ target: { id, value } }) {
        setFormData({ ...formData, [id]: value })
    }
    function handleCheckboxChange(value){
        let newSelectList= formData.function_type
        if(formData.function_type.includes(value)){
            let index = formData.function_type.indexOf(value)
            newSelectList.splice(index, 1)
        }
        else{
            newSelectList.push(value)
        }
        setFormData({ ...formData, function_type: newSelectList })
    }
    function handleRun() {
        props.createLlm(formData)
    }
    const typeList = ["Keyword", "Topic", "Sentiment", "Emotion"]
    return (
        <div>
            <span className="form-section">
                <h4>Create New LLM</h4>
                <div className="inline margin-bottom">
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Vendor Name" id="vendor_name" onChange={handleFormChange} ></input>
                    </span>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Engine Name" id="engine_name" onChange={handleFormChange} ></input>
                    </span>
                </div>
                <div className="inline margin-bottom">
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Cost" id="cost" onChange={handleFormChange} ></input>
                    </span>
                    <span className="text-input-container text-input">
                        <input type="text" className="form-control text-input-container" placeholder="Cost Base Tokens" id="cost_base_tokens" onChange={handleFormChange} ></input>
                    </span>
                </div>
                <textarea className="form-control text-area margin-bottom" placeholder="Prompt Format Long" id="prompt_format_long" onChange={handleFormChange}></textarea>
                <textarea className="form-control text-area margin-bottom" placeholder="Prompt Format Short" id="prompt_format_short" onChange={handleFormChange}></textarea>
                <div className="inline">
                    <Form>
                    <div key={`default-checkbox`} className="mb-3 inline">
                    {typeList.map((type, i) => <div className="inline-small-gap" key={type}><Form.Check onClick={e=> handleCheckboxChange(type)} key={i} value={type} type={"checkbox"} id="type"/>{type}</div>)}
                    </div>
                    </Form>
                </div>
                {results && results.message? (results.success? <Alert className="alert" variant="success">{results.message}</Alert>: <Alert className="alert" variant="danger">{results.message + ": " + results.data}</Alert>) : null}
            </span>
            <div className="run">
                {isComplete ? <Button className="button-run" onClick={handleRun}>Create</Button> : <Button className="button-run" disabled onClick={handleRun}>Create</Button>}
            </div>
        </div>
    )
}